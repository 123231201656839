import React, {forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState} from "react";
import DataGrid, {DataGridProps} from "./Datagrid";
import {RequestFilter} from "../../Redux/Api/Request/DynamicListRequest";
import {useAppSelector} from "../../Redux/store";
import DataGridNoRowsOverlay from "./DataGridNoRowsOverlay";

type DynamicDataGridProps = Omit<DataGridProps, "rows"> & {
    organisation: string,
    filter?: RequestFilter,
    updateInterval?: number,
    queryHook: any,
    datachange?: (data: { dataid: Array<string> }) => void;
};


export type DynamicDataGridHandle = {
    reload: () => void,
}

const DynamicDataGrid: ForwardRefRenderFunction<DynamicDataGridHandle, DynamicDataGridProps> = ({filter, organisation, queryHook, updateInterval, datachange,...props}, ref) => {    const [page, setPage] = useState(props.page || 0);
    const [pageSize, setPageSize] = useState(props.pageSize || 25);
    const [sort, setSort] = useState(props.sortModel);
    const {data, isFetching, refetch} = queryHook({
        id: organisation,
        filter,
        page: (page + 1),
        pageSize,
        sort
    });

    useImperativeHandle(ref, () => ({
        reload() {
            refetch();
        }
    }));

    useEffect(() => {
        if(!isFetching && data){
            if (datachange) {
                if (data.id) {
                    datachange({ dataid: [data.id] }); 
                } else {
                    datachange({ dataid: []});
                }
            }
        }
    }, [data]);
    
    let rowcount = data ? data.totalItems : 0;
    let rows: Array<object> = [];
    if (!isFetching && data) {
        rows = data.cards;
        if(!rows || rowcount <= 0 ){
            // @ts-ignore
            return <DataGrid columns={[]} rows={[]}><DataGridNoRowsOverlay/></DataGrid>
        } 
    }

    return (
        <DataGrid
            loading={isFetching}
            paginationMode="server"
            onPageChange={(page: any) => setPage(page)}
            onPageSizeChange={(pageSize: any) => setPageSize(pageSize)}
            sortingMode="server"
            onSortModelChange={(sort: any) => setSort(sort)}
            rowCount={data ? data.totalItems : 0}
            rows={rows}
            data={data ? data.id ?? 0 : 0}
            {...props}
        />
    );
}

export default forwardRef(DynamicDataGrid);