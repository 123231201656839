import { motion } from 'framer-motion'
import React from 'react'
import { Outlet } from 'react-router-dom'

export interface LandingWrapperProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {

}

import PreviewPng from '../../Assets/landingpageImage.png'

const LandingWrapper = (props: LandingWrapperProps) => {
    return (
        <div {...props} className='flex flex-grow relative h-full'>
            <Outlet/>
            <div style={{ perspective: 500, transformStyle: 'preserve-3d' }} className='hidden lg:flex bg-gradient-to-l from-accent to-light-200 text-white flex-grow h-screen justify-center items-center overflow-hidden'>
                <img className='shadow-2xl w-full h-full max-w-none' src={PreviewPng} alt="Preview" />
            </div>
        </div>
    )
}

export default LandingWrapper