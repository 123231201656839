import Title from "../../../Shared/Title";
import Error from "../../../Shared/Form/Error";
import Success from "../../../Shared/Form/Success";
import React, {useState} from "react";
import {useUploadMutation} from "../../../../Redux/Api/Api";
import {usePostSponsorMutation} from "../../../../Redux/Api/Sponsors";
import {useAddSponsorLogoMutation} from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import {all, max, min} from "../../../../Utils/InValidation";
import Input from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import {useAppSelector} from "../../../../Redux/store";
import {Hydra} from "../../../../Utils/Hydra";
import {SponsorResponse} from "../../../../Redux/Api/Request/Sponsors";
import {useAddNewsLogoMutation, usePostNewsMutation} from "../../../../Redux/Api/News";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'

const AddNewsPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [ uploadFile ] = useUploadMutation();
    const [ postNews ] = usePostNewsMutation();
    const [ addNewsLogo ] = useAddNewsLogoMutation();

    const [ busy, setBusy ] = useState(false);
    const [ error, setError ] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('');
    const [ fileBlob, setFileBlob ] = useState('');
    const [ text, setText ] = useState('');

    const submit = async (data: {
        title: string,
        image: File,
    }) => {
        if(busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        if (text.length > 49000){
            setError(t('organisation:news:tooLong') as string);
            setBusy(false);
            return;
        }

        try {
            let upload_id;
            if (data.image) {
                upload_id = await uploadFile({
                    file: data.image
                }).unwrap();
            }


            const outData = {
                title: data.title,
                text: text,
                url: '',
                organisation: org_id
            }

            const sponsor_id = await postNews([org_id, outData ]).unwrap();

            if (upload_id) {
                await addNewsLogo({
                    news_id: sponsor_id.toString(),
                    upload_id
                });
            }

            setSuccessMessage(t('organisation:news:successAdd') as string);
            setBusy(false)

            navigate('/dashboard/organisation/news')
        } catch(e) {
            setError(t('modal:error') as string);
            setBusy(false);
        }
    }

    return (
        <div>
            <Title text={t('organisation:news:title')} subtitle={t('organisation:news:addNews') as string} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />

            <BusyArea busy={ busy }>
                <Form submit={ submit } style={{ width: 1000}}>
                    <Input
                        required
                        invalidator={ all(min(2), max(255)) }
                        label={t('organisation:news:tableTitle')}
                        id='title'/>
                    <FilePicker required id='image' label={t('organisation:news:image')} onChangeBlob={ setFileBlob } accept={['image/*']} />
                    <label>{t('organisation:news:text')}</label>
                    <ReactQuill
                        value={ text }
                        onChange={ setText }
                        id='text'
                        className={'mb-14 bg-transparent p-1 border-black border-opacity-10 h-80 '}
                    />
                    <div className='flex'>
                        <Button title={t('organisation:news:save')} />
                    </div>
                </Form>
            </BusyArea>
        </div>
    )
}

export default AddNewsPage