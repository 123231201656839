import React from 'react'
import Title from '../Title'

export interface SevereErrorProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    error?: string,
    subtitle?: string
}

const SevereError = (props: SevereErrorProps) => {

    const { error, subtitle, ...restProps } = props

    if(!error) return <></>

    return (
        <Title {...restProps} className='text-white bg-red-500 p-10 max-w-screen-lg' subtitle={ subtitle } text={ error } />
    )
}

export default SevereError