import { faAdd } from '@fortawesome/pro-light-svg-icons'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {useUploadMutation} from '../../../../Redux/Api/Api';
import {useAddLogoMutation, usePutThemeMutation, useGetOrganisationQuery, usePutOrganisationMutation ,useLazyGetOrganisationSettingsQuery} from '../../../../Redux/Api/Organisations';
import { putThemeLocal } from '../../../../Redux/State/Organisation'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import ColorPicker from '../../../Shared/Form/ColorPicker'
import Error from '../../../Shared/Form/Error'
import FilePicker from '../../../Shared/Form/FilePicker'
import Form from '../../../Shared/Form/Form'
import Success from '../../../Shared/Form/Success'
import ThemePreview from '../../../Shared/ThemePreview'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import {useTranslation} from "react-i18next";
import Input from '../../../Shared/Form/Input'
import validator from 'validator';

const ThemePage = () => {

    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const [ putTheme ] = usePutThemeMutation()
    const [ uploadFile ] = useUploadMutation()
    const [ addLogo ] = useAddLogoMutation()
    const [ putOrganisation ] = usePutOrganisationMutation()
    const [ getOrganisationsSettings, {data: settings} ] = useLazyGetOrganisationSettingsQuery()

    const [ busy, setBusy ] = useState(false)
    const [ tint, setTint ] = useState('#E6171A')
    const [ lighterTint, setLighterTint ] = useState('#ed4245')
    const [ fileBlob, setFileBlob ] = useState('')
    const [ error, setError ] = useState('')
    const [ successMessage, setSuccessMessage ] = useState('')

    const [ organisationEmail, setOrganisationEmail ] = useState('');


    const { data, isError } = useGetOrganisationQuery({ id: org_id! }, {
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        if(!theme) return

        setTint(theme.colors.tint)
        setLighterTint(theme.colors.lighterTint)

        setFileBlob(theme.icon)
    }, [ theme ])

    
    useEffect(() => {
        if(org_id)
            getOrganisationsSettings(org_id)

        if(settings) {
            setOrganisationEmail(settings.organisationEmail)
        }
    }, [ org_id, settings ])

    const isEmailValid = (email: string) => {
        return validator.isEmail(email);
    };

    const submit = async ({ logo, organisationEmail, }: { logo: File, organisationEmail: string, }) => {
        if(busy) return
        setBusy(true)
        setError('')
        setSuccessMessage('')
        if(isEmailValid(organisationEmail)){
            try {
                const settings = {
                    background: '',
                    normal: '',
                    border: '',
                    inactive: '',
                    text: '',
                    lighterText: '',
                    tint,
                    lighterTint,
                    organisationEmail
                }
    
                await putTheme({
                    id: org_id,
                    settings
                }).unwrap()
    
                dispatch(putThemeLocal(settings))
    
                if(logo) {
                    const upload_id = await uploadFile({
                        file: logo
                    }).unwrap()
    
                    await addLogo({
                        organisation_id: org_id,
                        upload_id
                    })
                }
    
                setSuccessMessage( t('organisation:theme:success') as string)
            } catch(e) {
                let err = e as Hydra.Error
                setError(err['hydra:description'])
                setBusy(false)
            }
        } else{
            setError(t('organisation:theme:validEmail') || '');

        }

        setTimeout(() => setBusy(false), 200)
    }

    if(!theme) return <LoadingPage/>
    if(!data || !settings) return <LoadingPage/>

    return (
        <>
            <Title text={t('organisation:theme:title')} subtitle={t('organisation:theme:subtitle') as string} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />
            
            <div className='flex flex-col'>
                <p style={{whiteSpace: "pre-line"}}>
                    {t('organisation:theme:description')}
                </p>
            </div>

            <BusyArea busy={ busy }>
                <Form submit={ submit }>
                    <div className='flex flex-wrap flex-grow gap-x-20 gap-y-10 mt-5'>
                        <div className='flex flex-grow flex-col max-w-1/3'>
                            <ColorPicker initial='#E6171A' color={ tint } onChangeReactive={ setTint } id='tint' label= {t('organisation:theme:primaryColor')} />
                            <ColorPicker initial='#ed4245' color={ lighterTint } onChangeReactive={ setLighterTint } id='lighterTint' label= {t('organisation:theme:secondaryColor')} />
                            <Input
                                required
                                label={t('organisation:theme:replyEmail')}
                                initial={data.settings?.organisationEmail || 'support@digipas.app'}
                                id='organisationEmail'/>
                            <FilePicker id='logo' label='Logo' onChangeBlob={ setFileBlob } accept={['image/*']} />
                            <div className='flex'>
                                <Button title= {t('organisation:theme:save')} />
                            </div>
                        </div>
                        <ThemePreview tint={ tint } lighterTint={ lighterTint } fileBlob={ fileBlob } />
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default ThemePage