import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {useGetOrganisationsQuery} from '../../../../Redux/Api/Organisations';
import {useGetUserQuery, usePutUserMutation} from '../../../../Redux/Api/Users';
import { useAppSelector } from '../../../../Redux/store'
import {Strings} from '../../../../Strings/nl';
import { Hydra } from '../../../../Utils/Hydra'
import { JWT } from '../../../../Utils/JWT'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Dropdown from '../../../Shared/Form/Dropdown'
import Error from '../../../Shared/Form/Error'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import Success from '../../../Shared/Form/Success'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import Page404 from '../../Page404'
import {useTranslation} from "react-i18next";

const UsersEditPage = () => {

    const modalContext = useContext(ModalContext)
    const { t } = useTranslation()

    const { id: userId } = useParams()

    if(!userId) return <Page404/>

    const org_id = useAppSelector((s) => s.organisation.id)!
    const roles = JWT.read<Array<string>>('roles') || []
    const hidden = { hidden: 'false' };

    const { data: orgs } = useGetOrganisationsQuery(hidden, {
        refetchOnMountOrArgChange: true
    });
    
    const { data, refetch, isError } = useGetUserQuery(userId!)
    const [ putUser ] = usePutUserMutation()

    const [ isActive, setIsActive ] = useState(true)
    const [ error, setError ] = useState('')
    const [ busy, setBusy ] = useState(false)
    const [ successMessage, setSuccessMessage ] = useState('')

    useEffect(() => {
        if(!data) return

        setIsActive(data.active)
    }, [ data ])

    const submit = async (data: { email: string, firstname: string, lastname: string, organisationId: string, role: string }) => {
        if(FailedRequirements(data, 'firstname', 'lastname', 'role')) return setError(t('modal:missing') as string)
        if(busy) return
        
        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putUser([ userId, data ]).unwrap()

            refetch()
            setSuccessMessage(t('user:list:success') as string)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const changeActivation = async (new_state: boolean) => {
        if(busy) return
        
        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putUser([ userId, {
                active: new_state
            } ]).unwrap()

            refetch()
            setSuccessMessage(t('user:list:user') as string)
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const activate = () => changeActivation(true)
    const deactivate = () => changeActivation(false)
    
    const submitReset = () => {
        // TODO: Implement reset password
    }

    if(isError) return <Page404/>
    if(!data || !orgs) return <LoadingPage/>

    return (
        <>
            <Title text={t('user:list:edit')} textNotBold/>
            <Error text={ error } />
            <Success text={ successMessage } />
            <BusyArea busy={ busy }>
                <Form className='mb-4' submit={ submit }>
                    <Input
                        initial={ data.email }
                        label={t('user:list:email')}
                        id='email'/>
                    <Input
                        required
                        initial={ data.firstname }
                        label={t('user:list:firstname')}
                        id='firstname'/>
                    <Input
                        required
                        initial={ data.lastname }
                        label={t('user:list:lastname')}
                        id='lastname'/>
                    { (org_id && !roles.includes('ROLE_SUPER_ADMIN')) ? (
                        <input type='hidden' value={ org_id } name='organisationId' />
                    ): (
                        <Dropdown label={t('user:list:organisation')} initial={ orgs[0].id } id='organisationId' options={
                            Object.fromEntries(orgs.map(org => ([org.id, org.name])))
                        } />
                    ) }
                    {roles.includes('ROLE_ADMIN') ? (
                        <Dropdown id='role' label='Rol' initial='ROLE_USER' options={{
                            'ROLE_ADMIN': Strings.nl.roles['ROLE_ADMIN'],
                            'ROLE_USER': Strings.nl.roles['ROLE_USER']
                        }} />
                    ) : (
                        <input type='hidden' value={data.role} name='role' />
                    )}
                    <Button title={t('user:list:save')}/>
                    <div className='flex mt-4 gap-4'>
                        <Button 
                            nosubmit title={ (isActive ? t('user:list:deactivate') : t('user:list:activate')) }
                            action={ modalContext.withModal({ title: t('modal:attention'), body: t('modal:changes') }, isActive ? deactivate : activate) } />
                        {/*<Button */}
                        {/*    nosubmit title='Wachtwoord resetten' action={ submitReset } />*/}
                    </div>
                </Form>
            </BusyArea>
        </>
    )
}

export default UsersEditPage