import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'

export interface SidePanelWrapperProps extends HTMLMotionProps<"div"> {

}

const SidePanelWrapper = (props: SidePanelWrapperProps) => {
    return (
        <motion.div {...props} initial={{ translateX: '-.5rem' }} animate={{ translateX: '0rem' }} className='w-screen lg:w-1/3 h-screen p-20 bg-light-200'/>
    )
}

export default SidePanelWrapper