import {
    useCheckOutCardsMutation,
    useLazyGetCheckedInScansTodayQuery,
    useLazyGetCheckedOutScansTodayQuery,
    useLazyGetScansQuery,
}
 from '../../../../Redux/Api/Organisations';
import Title from '../../../Shared/Title'
import Card from '../../../Shared/Card/Card'
import CardGrid from '../../../Shared/Card/CardGrid'
import CardValue from '../../../Shared/Card/CardValue'
import HourGraph from './HourGraph'
import ReleaseNotes from './ReleaseNotes'
import {faCalendarMinus } from '@fortawesome/pro-solid-svg-icons'
import {faCalendarDays } from '@fortawesome/pro-light-svg-icons'
import {useAppSelector} from '../../../../Redux/store'
import React, {useContext, useEffect, useState} from 'react'
import {JWT} from '../../../../Utils/JWT'
import Button from "../../../Shared/Form/Button"
import {Hydra} from "../../../../Utils/Hydra"
import Error from "../../../Shared/Form/Error"
import Success from "../../../Shared/Form/Success"
import BusyArea from '../../../Shared/BusyArea'
import moment from "moment"
import {ModalContext} from "../../../Shared/Modal/Modal"
import { useTranslation } from 'react-i18next'
import GraphFilter from "./graphfilter"
import UserScan from "./UserScan"
import { useLocation, useNavigate } from 'react-router-dom'
import NotificationHelper from "../../../../Helper/NotificationHelper";

const Overview = () => {

    const [getScans, { data: scans }] = useLazyGetScansQuery();
    const [ getCheckedInScansToday, { data: checkedInScansToday }] = useLazyGetCheckedInScansTodayQuery();
    const [ getCheckedOutScansToday, { data: checkedOutScansToday }] = useLazyGetCheckedOutScansTodayQuery();
    const org_id = useAppSelector((s) => s.organisation.id);
    const [ checkOutCards ] = useCheckOutCardsMutation();

    const modalContext = useContext(ModalContext)
    const { t } = useTranslation()
    const navigate = useNavigate()

    const first_name = JWT.read<string>('given_name')
    const [ busy, setBusy ] = useState(false);
    const [ error, setError ] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('');
    const [hoveredButtons, setHoveredButtons] = useState<{[key: string]: boolean}>({});

    const [ checkIns, setCheckIns ] = useState(0)
    const [ checkOuts, setCheckOuts ] = useState(0)
    const [ checkedIns, setCheckedIns] = useState(0)
    const [ uniekcheckIns, setUniekCheckIns] = useState(0)
    const [grafiek, setGrafiek] = useState(false);


    const [ checkedInstoday, setCheckInsToday ] = useState(0)
    const [ checkOutsToday, setCheckOutsToday ] = useState(0)

    const [selectedDates, setSelectedDates] = useState({
        toDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        fromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),

    });

    let [checkGraph, setCheckGraph] = useState(new Array(3).fill(new Array(15).fill(0)))

    useEffect(() => {
        setBusy(true)
        if (org_id && selectedDates.fromDate && selectedDates.toDate) {
            const loadData = async () => {
                const toDate = new Date(selectedDates.toDate);
                const fromDate = new Date(selectedDates.fromDate);

                toDate.setDate(toDate.getDate() + 1);

                getScans({
                    org_id,
                    fromDate: formatDateToYMD(fromDate, 2),
                    toDate: formatDateToYMD(toDate, 2),
                });
            }
            loadData();

        }
        setBusy(false)
    }, [selectedDates, org_id, getScans, grafiek]);

    const handleDatesChange = (dates: { fromDate: Date; toDate: Date }) => {
        setSelectedDates(dates);
    };

    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number)
        return date.toISOString().slice(0, 10);
    };

    const calculateTotal = (arrOrNumber: number) => {
        if (Array.isArray(arrOrNumber)) {
            const trimmedArray = arrOrNumber.slice(0, -1);
            return trimmedArray.reduce((acc, curr) => acc + curr, 0);
        } else {
            return arrOrNumber;
        }
    };

    useEffect(() => {
        if (!org_id) return;

            getCheckedInScansToday(org_id);
            getCheckedOutScansToday(org_id);


    }, [org_id]);

    useEffect(() => {
        if (checkedInstoday !== undefined && checkOutsToday !== undefined) {
            if (checkedInstoday >= checkOutsToday) {
                setCheckedIns(checkedInstoday - checkOutsToday);
            }
        }
    }, [checkedInstoday, checkOutsToday]);

    useEffect(() => {
        if (checkedInScansToday !== undefined && checkedOutScansToday !== undefined) {
            // Update state with correct values
            setCheckInsToday(checkedInScansToday);
            setCheckOutsToday(checkedOutScansToday);
        }
    }, [checkedInScansToday, checkedOutScansToday]);


    useEffect(() => {
        if (scans && scans.length > 0) {
            const [checkedins, checkins, checkouts, uniekcheckins] = scans;
            if(calculateTotal(checkins) != 0 ||  calculateTotal(checkouts) != 0){
                setGrafiek(true);
                setCheckGraph(scans);
            } else{
                setGrafiek(false);
                setCheckGraph(scans);
            }

            if (checkedOutScansToday != null && checkedInScansToday != null) {
                if (checkedInScansToday >= checkedOutScansToday) {
                    setCheckedIns(checkedInScansToday - checkedOutScansToday);
                }
            }
            setCheckIns(calculateTotal(checkins));
            setCheckOuts(calculateTotal(checkouts));
            setUniekCheckIns(calculateTotal(uniekcheckins));
        }
    }, [scans, checkedOutScansToday, checkedInScansToday]);

    const handleMouseEnter = (buttonId: string) => {
        setHoveredButtons((prev) => ({ ...prev, [buttonId]: true }));
    };

    const handleMouseLeave = (buttonId: string) => {
        setHoveredButtons((prev) => ({ ...prev, [buttonId]: false }));
    };

    const handleClick = (check: string): React.MouseEventHandler<HTMLDivElement> => (event) => {
        if(check !== 'checkedin'){
            navigate('/dashboard/cards/list', { state: { todate:  formatDateToYMD(selectedDates.toDate, 2), fromdate: formatDateToYMD(selectedDates.fromDate, 2), check: check} });
        } else{
            navigate('/dashboard/cards/list', { state: { todate:  formatDateToYMD(new Date, 2), fromdate: formatDateToYMD(new Date, 2), check: 'checkedin'} });
        }
    };

    const handleLinkClick = (check: string): React.MouseEventHandler<HTMLAnchorElement> => (event) => {
        event.preventDefault();
        if(check !== 'checkedin'){
            navigate('/dashboard/cards/list', { state: { todate:  formatDateToYMD(selectedDates.toDate, 2), fromdate: formatDateToYMD(selectedDates.fromDate, 2), check: check} });
        } else{
            navigate('/dashboard/cards/list', { state: { todate:  formatDateToYMD(new Date, 2), fromdate: formatDateToYMD(new Date, 2), check: 'checkedin'} });
        }
    };

    const checkOut = async () => {
        if(busy) return;

        setBusy(true);

        try {
            if (org_id) {
                await checkOutCards(org_id);
            }
        } catch (e) {
            let err = e as Hydra.Error;
            NotificationHelper.showError(err['hydra:description']);
        }
        setBusy(false);
    }

    return (
        <>
            <ReleaseNotes/>
            <Error text={ error } />
            <Success text={ successMessage } />
            <BusyArea busy={ busy }>
                <Title textNotBold subtitle={t('overview:subtitle') as string } text={t('overview:welcome') + ` ${ first_name }` } className='mt-12'/>
                <Button
                    title={t('overview:checkout')}
                    action={ modalContext.withModal({ title:t('modal:title'), body:t('modal:body') },  checkOut) }
                    className='absolute right-10 top-20 float-right absolute w-[14rem] mb-5'
                />
                <GraphFilter icon={faCalendarDays} onDatesChange={handleDatesChange}/>
                <CardGrid cols={4} style={{alignItems: 'stretch', justifyItems: 'stretch', overflow: 'auto' }}>
                    <Card title={t('overview:currentCheckin')} rounded>
                        <CardValue
                            net='positive'
                            value={ checkedIns }
                            onMouseEnter={() => handleMouseEnter('1' as string)}
                            onMouseLeave={() => handleMouseLeave('1' as string)}
                            onClick={handleClick('checkedin')}
                            style={{ zIndex: 1, cursor: 'pointer'}}

                        />
                        <a
                            className={`text-end text-black p-1 px-3 ${hoveredButtons['1'] ? 'underline' : ''}`}
                            style={{cursor: 'pointer' }}
                            onMouseEnter={() => handleMouseEnter('1' as string)}
                            onMouseLeave={() => handleMouseLeave('1' as string)}
                            onClick={handleLinkClick('checkedin')}
                        >
                            {t('overview:lookAt')}
                        </a>
                    </Card>
                    <Card title={t('overview:uniqueUser')} rounded>
                        <CardValue
                            net='positive'
                            value={ uniekcheckIns }
                            onMouseEnter={() => handleMouseEnter('2' as string)}
                            onMouseLeave={() => handleMouseLeave('2' as string)}
                            onClick={handleClick('checkin')}
                            style={{ zIndex: 1, cursor: 'pointer'}}
                        />
                        <a
                            className={`text-end stext-black p-1 px-3 ${hoveredButtons['2'] ? 'underline' : ''}`}
                            style={{cursor: 'pointer' }}
                            onMouseEnter={() => handleMouseEnter('2' as string)}
                            onMouseLeave={() => handleMouseLeave('2' as string)}
                            onClick={handleLinkClick('checkin')}
                        >
                            {t('overview:lookAt')}
                        </a>
                    </Card>
                    <Card title={t('overview:checkinUser')} rounded>
                        <CardValue
                            net='positive'
                            value={ checkIns }
                            onMouseEnter={() => handleMouseEnter('3' as string)}
                            onMouseLeave={() => handleMouseLeave('3' as string)}
                            onClick={handleClick('checkin')}
                            style={{ zIndex: 1, cursor: 'pointer'}}
                        />
                        <a
                            className={`text-end text-black p-1 px-3 ${hoveredButtons['3'] ? 'underline' : ''}`}
                            style={{cursor: 'pointer' }}
                            onMouseEnter={() => handleMouseEnter('3' as string)}
                            onMouseLeave={() => handleMouseLeave('3' as string)}
                            onClick={handleLinkClick('checkin')}
                        >
                            {t('overview:lookAt')}
                        </a>
                    </Card>
                    <Card title={t('overview:checkoutUser')} rounded>
                        <CardValue
                            net='negative'
                            value={checkOuts}
                            onMouseEnter={() => handleMouseEnter('4' as string)}
                            onMouseLeave={() => handleMouseLeave('4' as string)}
                            onClick={handleClick('checkout')}
                            style={{ zIndex: 1, cursor: 'pointer'}}
                        />
                        <a
                        className={`text-end text-black p-1 px-3 ${hoveredButtons['4'] ? 'underline' : ''}`}
                        style={{cursor: 'pointer' }}
                        onMouseEnter={() => handleMouseEnter('4' as string)}
                        onMouseLeave={() => handleMouseLeave('4' as string)}
                        onClick={handleLinkClick('checkout')}
                        >
                            {t('overview:lookAt')}
                        </a>
                    </Card>

                </CardGrid>
                {grafiek ? (
                    <CardGrid cols={2} style={{alignItems: 'stretch', justifyItems: 'stretch', overflow: 'auto'}}>
                            <div className='flex-1 mb-4' >
                                    <HourGraph data={checkGraph} todate={selectedDates.toDate} fromdate={selectedDates.fromDate} />
                            </div>

                            <div className='flex-1 mb-4'>
                                    <UserScan todate={selectedDates.toDate} fromdate={selectedDates.fromDate}/>
                            </div>
                    </CardGrid>
                ) :(
                <Card className='h-92 z-10 mb-5 font-bold py-20  text-xl text-center' free>
                        <h1>{t('datagrid:noData')}</h1>
                </Card>
                )
                }
            </BusyArea>
        </>
    )
}

export default Overview
