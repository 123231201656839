import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {faEllipsis} from '@fortawesome/pro-solid-svg-icons'
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type RowAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type RowActionsProps = {
    actions: Array<RowAction>,
};

export default function RowActions({actions}: RowActionsProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Button
            style={{ border: '1px solid #D7E3F2' }}
            className='hover:bg-gray-200 text-black font-bold bg-white'
            onClick={handleClick}
            size="small"
            sx={{ ml: 0, mr: 0 }}
        >
            <div className="px-5 py-1">
                {t('datagrid:actions')}
                <FontAwesomeIcon  className="pl-2" icon={faEllipsis} />
            </div>
        </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'hidden',
                        borderRadius: 0,
                        border: '1px solid #D7E3F2',
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {actions.map(action =>
                    <MenuItem key={action.label} onClick={() => action.onClick()} className="px-5 py-0">
                        <ListItemText className="py-1 px-1 pr-8" style={{ borderBottom: '1px solid #dbdbdb' }}>{action.label}</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
