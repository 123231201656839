import React, {useEffect, useRef, useState} from 'react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { useAppDispatch } from '../../../Redux/store'
import { setSideBarOpen } from '../../../Redux/State/App'

export interface SidebarItemProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title: string,
    link?: string,
    exact?: boolean,
    sub?: boolean,
    bottom?: boolean
}

const SidebarItemVariants: Variants = {
    active: {
        scaleY: 1
    },
    inactive: {
        scaleY: 0
    }
}

const SidebarSubItemVariants: Variants = {
    open: {
        maxHeight: '30rem',
        overflow: 'visible'
    },
    closed: {
        maxHeight: '0rem'
    }
}

const SidebarItem = (props: SidebarItemProps) => {

    const location = useLocation()
    const active = props.link ? ( props.exact ? location.pathname == props.link : location.pathname.includes(props.link)) : false

    const [ isOpen, setIsOpen ] = useState(active)
    const [ isHover, setIsHover ] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const ref = useRef(null)

    const { children, sub, exact, title, bottom, ...restProps } = props

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
    }, [])

    const onClick = () => {
        if(props.children){
            setIsOpen((s) => !s)
        }
        else if(props.link) {
            navigate(props.link)
            dispatch(setSideBarOpen(false))
        }
    }

    const handleClickOutside = (e: { target: any }) => {
        // @ts-ignore
        if(!ref.current.contains(e.target)) {
            setIsOpen(false)
        }
    }


    return (
        <>
            <div {...restProps}
                 onMouseEnter={ () => setIsHover(true) }
                 onMouseLeave={ () => setIsHover(false) }
                 onClick={ onClick }
                 ref={ref}
                 style={{color: active || isHover ? '#FF0058' : 'black'
                 }}

                 className={`flex py-1 ${ !sub ? 'my-2' : 'opacity-90' } ${ bottom && 'mt-auto' } relative cursor-pointer`}>
                <AnimatePresence>
                    { active && <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} initial={{ maxWidth: '0%' }} exit={{ maxWidth: '0%' }} animate={{ maxWidth: '100%' }} className='absolute left-0 ml-6 top-[5%] w-[calc(100%-1.5rem)] h-[90%] opacity-[8%]'/> }
                </AnimatePresence>
                <div className={ `${ !sub && children && 'absolute left-[5%] -bottom-1 w-[90%] h-[2px] bg-light-300 opacity-40' }` }/>
                { !sub && (<motion.div
                    initial='inactive'
                    variants={ SidebarItemVariants }
                    animate={ active ? 'active' : 'inactive' }
                    style={{backgroundColor: '#FF0058'}}
                    className='transition-colors absolute top-0 h-full w-[3px]'/> ) }
                {
                    active && sub && (
                        <motion.div
                            layoutId='active_bar'
                            initial='active'
                            style={{ backgroundColor: '#FF058'}}
                            className='transition-colors absolute top-0 h-full w-[2px]'/>
                    )
                }
                <div className={`${!sub ? 'ml-6' : 'ml-10'} flex overflow-visible content-between flex-grow `} style={{ fontWeight: isHover || active ? 600 : 500 }}>
                    { title }
                    { children && (
                        <motion.div animate={ isOpen ? { rotate: '0deg' } : { rotate: '180deg' } } className='ml-auto flex justify-center items-center px-5'>
                            <FontAwesomeIcon className='w-2 h-2' icon={ faChevronDown } />
                        </motion.div>
                    ) }
                </div>
            </div>
            { children && (
                <motion.div ref={ref} className='overflow-hidden' transition={{ duration: 0.3 }} initial='closed' variants={ SidebarSubItemVariants } animate={ isOpen ? 'open' : 'closed' }>
                    { children }
                </motion.div>
            ) }
        </>
    )
}

export default SidebarItem