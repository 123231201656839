import * as React from "react";
import {LoginStatus} from "../../Redux/State/Auth";
import {Navigate} from "react-router-dom";
import { useAppSelector } from "../../Redux/store";
import { JWT } from "../../Utils/JWT";

export interface PrivateRouteProps {
    children: React.ReactElement | Array<React.ReactElement>
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const loginStatus = useAppSelector(state => state.auth.status)

    const exp = JWT.read<number>('exp')

    if (!exp || Date.now() >= exp * 1000 || loginStatus === LoginStatus.NOT_LOGGED_IN) {
        localStorage.removeItem('token')
        window.location.pathname = '/login'
    }

    return <>{ children }</>
}

export default PrivateRoute;