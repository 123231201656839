import { faBars, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HTMLMotionProps, motion } from 'framer-motion'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export interface SidebarProps extends HTMLMotionProps<'div'> {

}

import IconSVG from '../../../Assets/icon.svg?component'
import { setSideBarOpen } from '../../../Redux/State/App'
import { useAppDispatch, useAppSelector } from '../../../Redux/store'
import nlflag from '../../../Assets/nl.svg'
import enflag from '../../../Assets/en.svg'
import deflag from '../../../Assets/de.svg'
import esflag from '../../../Assets/es.svg'
import frflag from '../../../Assets/fr.svg'
import FormControl from '@mui/material/FormControl';
import {InputLabel, MenuItem, Select} from "@mui/material";
import {SelectChangeEvent} from "@mui/material/Select";

const Sidebar = (props: SidebarProps) => {

    const { children, ...restProps } = props

    const isOpen = useAppSelector((s) => s.app.sidebaropen)
    const dispatch = useAppDispatch()
    const [language, setLanguage] = useState('NL');
    const {t, i18n} = useTranslation();

    const changeLanguage = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value.toLowerCase());
        setLanguage(event.target.value);
    }

    return (
        <>
            <motion.div
                {...restProps}

                animate={{
                    translateX: isOpen ? '0%' : '-100%',
                }}

                transition={{
                    duration: 0.3,
                }}
                style={{  }}
                className="flex fixed left-0 top-0 overflow-y flex-col w-screen md:w-80 md:!translate-x-0 h-screen bg-light-100 z-20">
                    
                <FormControl className="absolute ml-56 mt-4 cursor-pointer" style={{width: 75, height: 1}}>
                    <Select
                        sx={{ boxShadow: 'none',border: '1px solid #D7E3F2', borderRadius: '0%'}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={language}
                        onChange={changeLanguage}
                    >
                        <MenuItem value="NL"><img src={nlflag} alt="Dutch" id="NL" style={{width: 25, height: 20, paddingTop: 2, paddingBottom: 1}}/></MenuItem>
                        <MenuItem value="EN"><img src={enflag} alt="English" id="NL" style={{width: 27, height: 23, paddingTop: 2, paddingBottom: 1}}/></MenuItem>
                        <MenuItem value="DE"><img src={deflag} alt="German" id="NL" style={{width: 25, height: 20, paddingTop: 2, paddingBottom: 1}}/></MenuItem>
                        <MenuItem value="FR"><img src={frflag} alt="France" id="NL" style={{width: 25, height: 20, paddingTop: 2, paddingBottom: 1}}></img></MenuItem>
                        <MenuItem value="ES"><img src={esflag} alt="Spain" id="NL"  style={{width: 27, height: 20, paddingTop: 2, paddingBottom: 1}}/></MenuItem>
                    </Select>
                </FormControl>
                <div className='flex '>
                    <IconSVG className='mt-6 ml-5 w-10 h-10 text-brand'/>
                    <h1 className='mt-7 ml-3 font-bold' style={{fontSize: '22px'}}>Digipas</h1>

                    <div onClick={ () => dispatch(setSideBarOpen(false)) } className='md:hidden flex cursor-pointer items-center p-4 justify-center'>
                        <div className='flex items-center justify-center hover:bg-accent hover:text-white cursor-pointer p-2'>
                            <FontAwesomeIcon className='w-6 h-6 p-1' icon={ faXmark } />
                        </div>
                    </div>
                </div>
                { children }
            </motion.div>
            <div onClick={ () => dispatch(setSideBarOpen(true)) } className='md:hidden absolute left-0 top-0 z-10 flex items-center p-4 justify-center'>
                <div className='flex items-center justify-center bg-light-200 hover:bg-accent hover:text-white cursor-pointer p-2'>
                    <FontAwesomeIcon className='w-6 h-6 p-1' icon={ faBars } />
                </div>
            </div>
            <motion.div
                animate={{
                    width: isOpen ? '18rem' : '0rem',
                }}
                className="flex md:!w-72 h-screen"/>
        </>
    )
}

export default Sidebar
