import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetReleaseNoteQuery, usePutReleaseNoteMutation } from '../../../../Redux/Api/ReleaseNote';
import BusyArea from '../../../Shared/BusyArea';
import Button from '../../../Shared/Form/Button';
import Error from '../../../Shared/Form/Error';
import Form from '../../../Shared/Form/Form';
import Input from '../../../Shared/Form/Input';
import Title from '../../../Shared/Title';
import { useTranslation } from 'react-i18next';
import Success from '../../../Shared/Form/Success';
import Page404 from '../../Page404';
import LoadingPage from '../../LoadingPage';

type ReleaseNoteResponseItem = {
    title: string;
    version: string;
    text_nl?: string;
    text_en?: string;
    text_de?: string;
    text_fr?: string;
    text_es?: string;
    [key: string]: string | undefined; // Voor dynamische sleutels
};


const ReleaseNoteEditPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    if (!id) return <Page404 />;

    const releaseNoteRequest = { id };
    const { data, refetch } = useGetReleaseNoteQuery(releaseNoteRequest);
    const [putOrganisation] = usePutReleaseNoteMutation();

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    const submit = async (data: ReleaseNoteResponseItem) => {
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        for (const language of languages) {
            const textKey = `text_${language}`;
            if (data[textKey] && data[textKey]!.length > 150) {
                setError(`${t('organisation:news:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }

        try {
            const outData = {
                title: data.title,
                text: {
                    text_nl: data.text_nl || '',
                    text_en: data.text_en || '',
                    text_de: data.text_de || '',
                    text_fr: data.text_fr || '',
                    text_es: data.text_es || '',
                },
                version: data.version,
            };

            await putOrganisation([id, outData]).unwrap();
            navigate('/dashboard/admin/releaseNote/list');
        } catch (e) {
            setError(t('modal:error') as string);
        } finally {
            setBusy(false);
        }
    };

    if (!data) return <LoadingPage />;

    // Casting data to ReleaseNoteResponseItem to ensure TypeScript recognizes the keys
    const releaseNoteData = data as unknown as ReleaseNoteResponseItem;

    return (
        <>
            <Title text={t('releasenote:changeReleasenote')} textNotBold />
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <Error text={error} />
                    <Success text={successMessage} />
                    <Input required label={t('releasenote:title')} id='title' initial={releaseNoteData.title} />
                    <Input required label={t('releasenote:version')} id='version' initial={releaseNoteData.version} />
                    <h1 className='text-2xl font-bold'>{t('organisation:news:text')}</h1>
                    <br />
                    <Input required label={t('organisation:news:text') + '(NL)'} id='text_nl' initial={releaseNoteData.text_nl || ''} />
                    <Input required label={t('organisation:news:text') + '(EN)'} id='text_en' initial={releaseNoteData.text_en || ''} />
                    <Input required label={t('organisation:news:text') + '(DE)'} id='text_de' initial={releaseNoteData.text_de || ''} />
                    <Input required label={t('organisation:news:text') + '(FR)'} id='text_fr' initial={releaseNoteData.text_fr || ''} />
                    <Input required label={t('organisation:news:text') + '(ES)'} id='text_es' initial={releaseNoteData.text_es || ''} />
                    <Button title={t('releasenote:save')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default ReleaseNoteEditPage;
