import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect} from 'react';
import { useLazyGetUsersQuery} from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import { useTranslation } from "react-i18next";
import { useLazyGetCheckedInScansQuery,
    useLazyGetCheckOutScansQuery,
    useLazyGetCheckScansQuery } from '../../../../Redux/Api/Organisations';

export interface UserScan {
    todate: Date;
    fromdate: Date;
}

const UserScan = (props: UserScan) => {
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { t } = useTranslation()

    const [getUsers, { data: users }] = useLazyGetUsersQuery();

    const [ getCheckedInScans, { data: checkedInScans }] = useLazyGetCheckedInScansQuery()
    const [ getCheckOutScans, { data: checkOutScans }] = useLazyGetCheckOutScansQuery()
    const [ getCheckScans, { data: checkScans }] = useLazyGetCheckScansQuery()

    let rows = []
    let rij = [0];

    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number)
        return date.toISOString().slice(0, 10);
    };

    useEffect(() => {
        if (org_id) {
            getUsers(org_id)
        }
    }, [org_id])

    useEffect(() => {
        if (org_id) {
            const fromDate = formatDateToYMD(props.fromdate, 2);
            const toDate = formatDateToYMD(props.todate, 2);
            getCheckedInScans({ org_id, fromDate, toDate });
            getCheckOutScans({ org_id, fromDate, toDate });
            getCheckScans({ org_id, fromDate, toDate });

        }
    }, [org_id, props.fromdate, props.todate]);

    if (users !== undefined) {
        //@ts-ignore
        rows = users['hydra:member']
    }
    
    function Scans(id: string, action: string) {
        let num = 0

        if(action == 'check'){
            if(checkScans !== undefined) {
                const scans = Object.values(checkScans)
    
                for (let i = 0; i < scans.length; i++) {
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1
                    }
                }
            }
        } else if(action == 'checkin') {
            if(checkedInScans !== undefined) {
                const scans = Object.values(checkedInScans)
    
                for (let i = 0; i < scans.length; i++) {
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1
                    }
                }
            }
        } else if(action == 'checkout'){
            if(checkOutScans !== undefined) {
                const scans = Object.values(checkOutScans)
    
                for (let i = 0; i < scans.length; i++) {    
                    //@ts-ignore
                    if (scans[i]['scannedBy'] === id) {
                        num += 1
                    }
                }
            }
        }
        return num
    }

    return (
        <div style={{border: '1px solid #D7E3F2' , borderRadius: '2px'}}>
            <div className=' bg-white' style={{ minHeight: '500px', maxHeight: '500px', overflowY: 'scroll' }} >
                <TableContainer>
                    <div>
                        <h1 className='font-bold px-5 p-4 text-md '>{t('overview:numberScans')}</h1>
                    </div>
                    <Table aria-label="caption table">
                    <TableHead style={{ background: '#F4F8FB'}}>
                        <TableRow style={{fontSize: '14px'}}>
                            <TableCell style={{ fontFamily: 'Inter', fontSize: '16px' }} className='px-6 font-bold'>{t('overview:ScanAccount')}</TableCell>
                            <TableCell style={{ fontFamily: 'Inter', fontSize: '16px' }} align="right" className='text-center font-bold'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div style={{ width: '10px', height: '10px', backgroundColor: '#0175FF', borderRadius: '50%', marginRight: '4px',}}></div>
                                    {t('overview:check')}
                                </div>
                            </TableCell>
                            <TableCell style={{ fontFamily: 'Inter', fontSize: '16px' }} align="right" className='text-center font-bold'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#00C45E', borderRadius: '50%', marginRight: '4px',}}></div>
                                        {t('overview:checkin')}
                                </div>
                            </TableCell>
                            <TableCell style={{ fontFamily: 'Inter', fontSize: '16px' }} align="right" className='text-center font-bold'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#FF0058', borderRadius: '50%', marginRight: '4px',}}></div>
                                        {t('overview:scanout')}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any) => (
                            <TableRow key={row.id} style={{border: '1px solid #D7E3F2'}}>
                                <TableCell className='px-6' component="th" scope="row" style={{ color: '#5B6C79', fontFamily: 'Inter', fontSize: '16px'}}> {row.firstname}</TableCell>
                                <TableCell className='text-center' align="right" style={{ color: '#5B6C79', fontFamily: 'Inter', fontSize: '16px'}}>{Scans(row.id, 'check')}</TableCell>
                                <TableCell className='text-center' align="right" style={{ color: '#5B6C79', fontFamily: 'Inter', fontSize: '16px'}}>{Scans(row.id, 'checkin')}</TableCell>
                                <TableCell className='text-center' align="right" style={{ color: '#5B6C79', fontFamily: 'Inter', fontSize: '16px'}}>{Scans(row.id, 'checkout')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}

export default UserScan
