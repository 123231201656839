import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    title: string,
    // action?: () => void,
    action?: (arg?: any) => void;
    nosubmit?: boolean
    icon?: IconDefinition,
    iconright?: boolean,
    secondary?: boolean,
    disabled?: boolean
}

const Button = (props: ButtonProps) => {

    const { title, action, className, secondary, disabled, nosubmit, iconright, icon, ...restProps } = props

    return (
        <button type={ nosubmit ? 'button' : 'submit' } {...restProps}
            className={`${ className } ${ disabled && 'opacity-40' } flex cursor-pointer transition-colors min-w-[6rem] px-6 py-2 ${ secondary ? 'hover:bg-light-100 border-2 text-gray-700 border-accent-light' : 'text-white bg-accent hover:bg-accent-light' } justify-center gap-4 items-center`} onClick={ props.action }>
            { icon && !iconright && <FontAwesomeIcon className='w-3 h-3' icon={ icon } /> }
            { title }
            { icon && iconright && <FontAwesomeIcon className='w-3 h-3' icon={ icon } /> }
        </button>
    )
}

export default Button