import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {ReleaseNoteResponseItem, ReleaseNoteResponse, ReleaseNoteRequest,ReleaseNotePutRequest} from './Request/ReleaseNotes';

const ReleaseNoteApi = Api.injectEndpoints({
    endpoints: build => ({
        postReleaseNote: build.mutation< ReleaseNoteResponseItem, [Partial<ReleaseNoteResponseItem>]>({
            query: ([releasenote]) => ({
                url: 'api/releasenote',
                method: 'POST',
                body: JSON.stringify({...releasenote})
            })
        }),

        getReleaseNotes: build.query<ReleaseNoteResponse, void>({
            query: () => (`/api/public/releasenote`),
            transformResponse: Hydra.MultiResponseTransformer
        }),

        getReleaseNote: build.query<ReleaseNoteResponseItem, ReleaseNoteRequest>({
            query: ({ id }) => (`/api/releasenote/${ id }`),
            transformResponse: Hydra.SingleResponseTransformer
        }),

        deleteReleaseNote: build.mutation<void, string>({
            query: (id) => ({
                url: `/api/releasenote/${ id }`,
                method: 'DELETE'
            })
        }),
        putReleaseNote: build.mutation<ReleaseNoteResponseItem,[string, ReleaseNotePutRequest]>({
            query: ([id, organisation]) => ({
                url: '/api/releasenote/' + id,
                method: 'PUT',
                body: JSON.stringify(organisation)
            })
        }),
    }),
});   

export const {
    useGetReleaseNoteQuery,
    useGetReleaseNotesQuery,
    useDeleteReleaseNoteMutation,
    usePostReleaseNoteMutation,
    usePutReleaseNoteMutation, 
} = ReleaseNoteApi;
export default ReleaseNoteApi;
