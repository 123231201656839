import {  HTMLMotionProps, motion, useAnimation } from 'framer-motion'
import { useEffect } from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import { usePrevious } from '../Utils/UsePrevious'
import {LoginStatus} from "../Redux/State/Auth";
import { useAppSelector } from '../Redux/store';
import { JWT } from '../Utils/JWT';

export interface MainProps extends HTMLMotionProps<"div"> {

}

const Main = (props: MainProps) => {
    const controls = useAnimation()

    const location = useLocation()
    const prevLocation = usePrevious(location)

    useEffect(() => {
        if(prevLocation?.pathname !== location.pathname)
            controls.start({
                scale: [1.02, 1],
                opacity: [0, 0, 1]
            })
    }, [ location ])

    return (
        <div id='node_MAIN' className='flex flex-col flex-grow relative' style={{ background: '#F4F8FB' }}>
            <motion.div {...props} 
                initial={{ scale: 1.02, opacity: 0 }}
                animate={ controls }
                transition={{ duration: 0.4 }}
                className="px-5 md:pl-20 md:pr-10 pt-20 md:pt-10 flex-grow origin-center overflow-hidden min-w-full md:min-w-0 "/>
        </div>
    )
}


export default Main