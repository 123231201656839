import { faArrowRight, faCheck, faPlus, faQuestionCircle, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {useUploadMutation} from '../../../../Redux/Api/Api';
import {
    useAddLogoMutation, useImportCardsMutation, usePullFeedMutation,
    usePutNewsFeedMutation,
    usePutSocialsMutation,
    usePutThemeMutation,
} from '../../../../Redux/Api/Organisations';
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import Title from '../../../Shared/Title'
import WizardLine from './WizardLine'
import { JWT } from '../../../../Utils/JWT'
import { OrganisationSettings, Social } from '../../../../Redux/Api/Request/Organisations'
import ColorPicker from '../../../Shared/Form/ColorPicker'
import ThemePreview from '../../../Shared/ThemePreview'
import FilePicker from '../../../Shared/Form/FilePicker'
import { AnimatePresence, motion } from 'framer-motion'
import Input from '../../../Shared/Form/Input'
import Dropdown from '../../../Shared/Form/Dropdown'
import { all, max, min } from '../../../../Utils/InValidation'
import Form from '../../../Shared/Form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SevereError from '../../../Shared/Form/SevereError'
import { CSVLoader } from '../../../../Services/CSVLoader'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Table from '../../../Shared/Table/Table'
import Header from '../../../Shared/Table/Header'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Row from '../../../Shared/Table/Row'
import BusyArea from '../../../Shared/BusyArea'
import Error from '../../../Shared/Form/Error'
import { Hydra } from '../../../../Utils/Hydra'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { useNavigate } from 'react-router-dom'
import { putSettingsLocal, putThemeLocal } from '../../../../Redux/State/Organisation'
import Constants from '../../../../Constants'
import Switch from "../../../Shared/Form/Switch";
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

const WizardPage = () => {

    const steps = ['Informatie', 'Thema', 'Socials', 'Nieuws', 'Importeren']

    const organisation_name = JWT.read<string>('organisation_name')
    const org_id = useAppSelector((s) => s.organisation.id)!
    const theme = useAppSelector((s) => s.organisation.settings?.theme)

    const { t } = useTranslation()

    const modalContext = useContext(ModalContext)

    const dispatch = useAppDispatch()

    const [ progress, setProgress ] = useState(0)
    const [ busy, setBusy ] = useState(false)
    const [ tint, setTint ] = useState('#E6171A')
    const [ lighterTint, setLighterTint ] = useState('#ed4245')
    const [ fileBlob, setFileBlob ] = useState('')
    const [ socials, setSocials ] = useState<Array<Social>>([])
    const [ rssInfoOpen, setRssInfoOpen ] = useState(false)
    const [ cardFile, setCardFile ] = useState<File | null>(null)
    const [ fileIsValid, setFileIsValid ] = useState<string>('')
    const [ csvData, setCsvData ] = useState<Array<Array<string>>>()
    const [ isDataOpen, setIsDataOpen ] = useState(false)
    const [ error, setError ] = useState('')
    const [ height, setHeight ] = useState(0)

    const [ putTheme ] = usePutThemeMutation()
    const [ uploadFile ] = useUploadMutation()
    const [ addLogo ] = useAddLogoMutation()
    const [ putSocials ] = usePutSocialsMutation()
    const [ putFeed ] = usePutNewsFeedMutation()
    const [ pullFeed ] = usePullFeedMutation()
    const [ importCards ] = useImportCardsMutation()

    const ref = useRef<HTMLDivElement>(null)

    const checkHeight = (add?: number) => {
        if(!ref.current) return
        
        let rect = ref.current.getBoundingClientRect()

        setHeight(rect.height + (add || 0))
    }

    useEffect(() => {
        checkHeight(progress == 2 ? 50 : 0)
        setTimeout(() => checkHeight(50), 100)
        setRssInfoOpen(false)
    }, [ progress ])

    useEffect(() => {
        if(!theme) return

        setTint(theme.colors.tint)
        setLighterTint(theme.colors.lighterTint)

        setFileBlob(theme.icon)
    }, [ theme ])

    const addSocial = () => {
        setSocials((s) => [...s, {
            text: '',
            url: '',
            type: ''
        }])

        // 🤮
        setTimeout(() => checkHeight(50), 100)
        setTimeout(() => document.getElementById(`social${ socials.length }`)?.scrollIntoView(), 200)

    }

    const deleteSocial = (index: number) => {
        setSocials((s) => {
            let cp = [...s]

            cp.splice(index, 1)
            
            return cp
        })

        setTimeout(() => checkHeight(50), 100)
    }

    useEffect(() => {
        if(!cardFile) return

        setFileIsValid('')

        ;(async () => {
            const loadedData = await CSVLoader.load(await cardFile.text(), t)

            setCsvData(loadedData.data!)
            setIsDataOpen(true)

            if(loadedData.error) setFileIsValid(loadedData.error)
            setTimeout(() => checkHeight(50), 100)
        })()

    }, [ cardFile ])

    useEffect(() => checkHeight(50), [ fileIsValid ])
    useEffect(() => void setTimeout(() => checkHeight(50), 100), [  isDataOpen ])

    useEffect(() => {
        dispatch(putThemeLocal({
            background: '',
            normal: '',
            border: '',
            inactive: '',
            text: '',
            lighterText: '',
            tint: tint,
            lighterTint: lighterTint
        }))
    }, [ tint, lighterTint ])

    const finish = async (data: {
        feed: string,
        cards?: File,
        send: boolean,
        tint: string,
        lighterTint: string,
        logo?: File
    }) => {
        if(busy) return
        
        if(fileIsValid && !(await modalContext.show('Weet je zeker dat je wilt doorgaan?', 'Er is nog een fout met je CSV bestand: ' + fileIsValid))) {
            modalContext.hide()
            return
        }
        modalContext.hide()

        setBusy(true)

        let formattedData: Array<Social> = []

        for(let [k,v] of Object.entries(data)) {
            if(!k.startsWith('social_')) continue
            let [_, index, name] = k.split('_')

            if(!formattedData[parseInt(index)]) {
                (formattedData[parseInt(index)] as any) = {}
            }

            (formattedData[parseInt(index)] as any)[name] = v
        }

        const settings: OrganisationSettings = {
            theme: {
                colors: {
                    background: '',
                    normal: '',
                    border: '',
                    inactive: '',
                    text: '',
                    lighterText: '',
                    tint: data.tint,
                    lighterTint: data.lighterTint
                },
                icon: ''
            },
            socials: formattedData,
            news: {
                feed: data.feed
            },
            organisationLanguage: 'NL',
            organisationEmail: 'suport@digipas.app',
            hidden: false,
            LastReminderEmail: null
        }

        try {
            await putTheme({
                id: org_id,
                settings: settings.theme.colors
            }).unwrap()

            if(data.logo) {
                const upload_id = await uploadFile({
                    file: data.logo
                }).unwrap()

                await addLogo({
                    organisation_id: org_id,
                    upload_id
                })
            }

            if(socials.length > 0) {
                await putSocials({
                    id: org_id,
                    socials: settings.socials
                }).unwrap()
            }

            if(settings.news.feed) {
                await putFeed({
                    id: org_id,
                    feed: settings.news.feed
                }).unwrap()

                try {
                    await pullFeed(org_id).unwrap()
                } catch(e) {}
            }

            if(data.cards) {
                const file_id = await uploadFile({
                    file: data.cards
                }).unwrap()
    
                await importCards([org_id, file_id, data.send]).unwrap()
            }

            dispatch(putSettingsLocal(settings))

            window.location.pathname = '/dashboard/success'
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }
    }

    const finishSkip = async () => {
        if(busy) return
        
        setBusy(true)

        try {
            await putTheme({
                id: org_id,
                settings: theme!.colors!
            }).unwrap()

            window.location.pathname = '/dashboard'
        } catch(e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }
    }

    return (
        <Form className='flex flex-grow min-h-full items-center justify-center' nostyle submit={ finish }>
            <Card free className='flex flex-col w-full 2xl:w-7/12 my-20'>
                <BusyArea busy={ busy }>
                    <Title text='Welkom bij jouw Digipas!' subtitle='Laten we nog even alles op een rijtje zetten'/>
                    <WizardLine current={ progress } items={ steps } />
                    <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} animate={{ height }} className='flex flex-col relative'>
                        <div ref={ ref } className='absolute w-full flex flex-col my-4 gap-4 min-h-[20rem]'>
                        <div className={ `${ progress !== 0 && 'hidden' } flex flex-col` }>
                                We moeten nog even wat dingen op een rij zetten voordat de Digipas van { organisation_name } gebruikt kan worden!<br/>
                                Druk op "Volgende" om verder te gaan.
                            </div>
                            <div className={ `${ progress !== 1 && 'hidden' } flex flex-col` }>
                                <div className='flex flex-col'>
                                    Het thema verandert de kleuren van de Digipas. Deze kleuren worden getoond in zowel het portaal als in de Digipas. <br/>
                                    Het is aanbevolen om de primaire kleur te zetten naar de kleur van je organisatie. En de secundaire kleur te zetten naar een lichtere kleur daarvan.
                                </div>
                                <ColorPicker initial='#E6171A' color={ tint } onChangeReactive={ setTint } id='tint' label='Primaire kleur' />
                                <ColorPicker initial='#ed4245' color={ lighterTint } onChangeReactive={ setLighterTint } id='lighterTint' label='Lichtere primaire kleur' />
                                <FilePicker id='logo' label='Logo' onChangeBlob={ setFileBlob } accept={['image/*']} />
                                <ThemePreview tint={ tint } lighterTint={ lighterTint } fileBlob={ fileBlob } />
                            </div>
                            <div className={ `${ progress !== 2 && 'hidden' } flex flex-col` }>
                                <div className='flex flex-col'>
                                    De socials zijn social media links die in de Digipas getoond worden. (Zoals Facebook, Twitter, Instagram, enz.)
                                </div>
                                <div className='flex'>
                                    <Button nosubmit action={ addSocial } icon={ faPlus } iconright title='Voeg socialmedia toe' />
                                </div>
                                <div className='flex flex-col relative gap-4 mt-4'>
                                    <AnimatePresence>
                                        { socials.map((s, i) => (
                                            <motion.div
                                                key={ JSON.stringify(s) }
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 1.1, height: 0 }}
                                                id={ `social${i}` }
                                                className='flex flex-col flex-grow w-full'>
                                                <Card outline free>
                                                    <Input placeholder='Bijv: Onze Facebook' id={ `social_${i}_text` } initial={ s.text } required invalidator={ all(min(3), max(30))} label='Naam' sublabel={ 'Social ' + (i + 1) } />
                                                    <Input placeholder='Bijv: https://www.facebook.com/club' id={ `social_${i}_url` } initial={ s.url } required invalidator={ min(3) } label='URL' />
                                                    <Dropdown required id={ `social_${i}_type` } initial={ s.type } options={{ facebook: 'Facebook', twitter: 'Twitter', other: 'Overig' }} label='Type' />
                                                    <div className='flex'>
                                                        <Button secondary nosubmit action={ () => deleteSocial(i) } title='Verwijderen' icon={ faTrashAlt } />
                                                    </div>
                                                </Card>
                                            </motion.div>
                                        )) }
                                </AnimatePresence>
                                </div>
                            </div>
                            <div className={ `${ progress !== 3 && 'hidden' } flex flex-col gap-2` }>
                                <div className='flex flex-col'>
                                    De Digipas app kan ook nieuws van jouw organisatie tonen in de app.
                                    <div>
                                        Voeg hieronder een RSS Feed URL toe.
                                        <FontAwesomeIcon onClick={ () => setRssInfoOpen(s => !s)} className='cursor-pointer opacity-60 ml-1' icon={ faQuestionCircle }/>
                                    </div>
                                    <motion.div
                                        animate={{ scale: rssInfoOpen ? 1 : 0, opacity: rssInfoOpen ? 1 : 0 }}
                                        className='origin-top-left bg-light-200 p-4 mt-2'>Een RSS Feed laat ons het nieuws van jouw organisaties website halen. Geen idee waar je die kan vinden? Vraag je website provider of <a target='_blank' className='text-accent hover:text-accent-light' href='https://x-interactive.nl/contact/'>vraag ons</a>.</motion.div>
                                </div>
                                <Input id='feed' label='RSS Feed URL' />
                            </div>
                            <div className={ `${ progress !== 4 && 'hidden' } flex flex-col` }>
                                <SevereError subtitle='Het bestand kon niet ge-importeerd worden.' error={ fileIsValid } />
                                <Error text={ error } />
                                <div className='flex flex-col'>
                                    Als je al een lijst met passen hebt kan je die hier nu importeren. De passen moeten aangeleverd worden in CSV bestand (Dat je kan exporteren uit bijvoorbeeld Microsoft Excel) en moet het formaat volgen van het onderstaande template bestand.
                                </div>
                                <a className='my-2' href={ Constants.DOWNLOAD_URL_CARDS_TEMPLATE } download><Button secondary nosubmit title='Download het passen template bestand' /></a>
                                <FilePicker onChange={ setCardFile } accept={['.csv']} label='Passen CSV bestand' id='cards' />
                                { csvData && (
                                    <>
                                    <Button
                                        title={ isDataOpen ? 'Voorbeeld verbergen' : 'Voorbeeld bekijken' }
                                        className='mb-6'
                                        nosubmit
                                        action={ () => setIsDataOpen(s => !s) } />
                                        <motion.div 
                                            initial={{ maxHeight: '0vh' }}
                                            animate={{ maxHeight: isDataOpen ? '50vh' : '0vh' }}
                                            className='flex flex-col overflow-hidden w-full'>
                                            <Table itemsPerPage={ 50 }>
                                                <Header>
                                                    <Column name='Initialen'/>
                                                    <Column name='Achternaam'/>
                                                    <Column name='E-mailadres'/>
                                                    <Column name='Lidnummer'/>
                                                    <Column name='Geldig van'/>
                                                    <Column name='Geldig tot'/>
                                                    <Column name='Lid types'/>
                                                </Header>
                                                <Data>
                                                    { csvData.map((row, index) => (
                                                        <Row
                                                            errorAware
                                                            key={ index }
                                                            index={ index }
                                                            data={ row } />
                                                    )) }
                                                </Data>
                                            </Table>
                                        </motion.div>
                                    </>
                                ) }
                                <Switch id='send' label='Gelijk activatiemails versturen?' />
                            </div>
                        </div>
                    </motion.div>
                    <div className='flex justify-between'>
                        <Button disabled={ progress == 0 } action={ () => setProgress((s) => Math.max(s - 1, 0)) } secondary nosubmit title='Terug' icon={ faArrowLeft }/>
                        { progress !== steps.length - 1 && <Button action={ () => setProgress((s) => Math.min(s + 1, steps.length - 1)) } nosubmit title='Volgende' icon={ faArrowRight } iconright/> }
                        { progress === steps.length - 1 && <Button title='Voltooien' icon={ faCheck } iconright/> }
                    </div>
                </BusyArea>
                <div onClick={ modalContext.withModal({ title: 'Weet je het zeker?', body: 'Weet je zeker dat je dit wil overslaan?' }, finishSkip) } className='absolute right-0 -bottom-10 px-6 cursor-pointer hover:underline'>Ik wil <b>alles</b> overslaan</div>
            </Card>
        </Form>
    )
}

export default WizardPage