import {CardResponseItem} from "../../../Redux/Api/Request/Cards";
import moment from "moment";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useTranslation} from "react-i18next";

type StatusRendererProps = {
    card: CardResponseItem
}

export default function StatusRenderer({card}: StatusRendererProps) {
    const classes = useStyles();
    const { t } = useTranslation()

    if(card.block) {
        return(<div className={classes.error}><ErrorOutlineIcon fontSize='small' className='pb-1'/>{t('datagrid:block')}</div>);
    } else if(moment(card.activeFrom).format('YYYYMMDD') > moment().format('YYYYMMDD')) {
        return (<div className={classes.warning}><WarningAmberIcon fontSize='small' className='pb-1'/> {t('datagrid:inactive')}</div>);
    } else if(moment(card.activeUntil).format('YYYYMMDD') < moment().format('YYYYMMDD')) {
        return (<div className={classes.warning}><WarningAmberIcon fontSize='small' className='pb-1'/> {t('datagrid:inactive')}</div>);
    } else {
        return(<div className={classes.correct}><CheckIcon fontSize='small' className='pb-1'/> {t('datagrid:active')}</div>);
    }
}

const useStyles = makeStyles(theme => ({
    warning: {
        color: 'white',
        padding: '2px 10px 2px 10px',
        backgroundColor: '#fc9803',
        borderRadius: 20,
        lineHeight: 2,
    },

    correct: {
        color: 'white',
        padding: '2px 10px 2px 10px',
        backgroundColor: '#00C45E',
        borderRadius: 20,
        lineHeight: 2,
    },

    error: {
        color: 'white',
        backgroundColor: '#fc2c03',
        padding: '2px 10px 2px 10px',
        borderRadius: 20,
        lineHeight: 2,
    }
}));
