export namespace Strings {
    export const nl = {
        roles: {
            'ROLE_USER': 'Gebruiker voor scanners',
            'ROLE_ADMIN': 'Beheerder',
        },
        link: {
            'download_android': 'https://play.google.com/store/apps/details?id=com.xinteractive.android.digipas',
            'download_ios': 'https://apps.apple.com/nl/app/digipas.app/id1633608697'
        },
        message: {
            busy: 'We zijn even bezig...',
        },
        form: {
            required: 'Dit veld is verplicht'
        },
        error: {
            401: 'Je hebt niet de juiste rechten om deze actie uit te voeren. Of je session is verlopen.',
        },
        login_error: {
            'any': 'Er is een netwerk fout opgetreden. Probeer het later nog eens.',
            401: 'Email of wachtwoord is onjuist.',
            403: 'Geen toegang!'
        }
    }
}