import React, { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'

import DigipasLogoWidePNG from '../../../../Assets/digipaslogowide.png'
import { JWT } from '../../../../Utils/JWT'
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import Title from '../../../Shared/Title'

const SuccessPage = () => {

    const organisation_name = JWT.read<string>('organisation_name')

    const [ { width, height }, setSize ] = useState({ width: 0, height: 0 })
    const ref = useRef<HTMLDivElement>(null)

    const navigate = useNavigate()


    useEffect(() => {
        if(!ref.current) return

        const node = ref.current.closest('#node_MAIN')

        if(!node) return

        const resize = () => setSize(node!.getBoundingClientRect())

        setSize(node.getBoundingClientRect())


        node.addEventListener('resize', resize)

        window.addEventListener('resize', resize)

        return () => {
            node.removeEventListener('resize', resize)
            window.removeEventListener('resize', resize)
        }
    }, [ ref ])

    return (
        <div ref={ ref } className='flex flex-col flex-grow h-full items-center justify-center'>
            <Confetti
                className='absolute top-0 right-0 z-[2]'
                width={ width }
                height={ height }/>
            <Card className='w-11/12 md:w-1/2 min-h-[50vh] gap-10 z-[3]' free>
                <img className='w-8/12 mx-auto py-10' src={ DigipasLogoWidePNG } />
                <div className='flex flex-col ml-10 flex-grow'>
                    <Title text='Welkom bij jouw Digipas!' />
                    <div>De digipas van { organisation_name } is geactiveerd! Je kan nu beginnen met dingen aanpassen!</div>
                    <div className='flex flex-col mt-auto'>
                        <div>Spring snel naar:</div>
                        <div className='flex mt-4 flex-wrap gap-4'>
                            <Button action={ () => navigate('/dashboard') } title='Overzicht' />
                            <Button action={ () => navigate('/dashboard/cards') } title='Passen' />
                            <Button action={ () => navigate('/dashboard/organisation/theme') } title='Thema' />
                            <Button action={ () => navigate('/dashboard/organisation/news') } title='Nieuws' />
                            <Button action={ () => navigate('/dashboard/organisation/socials') } title='Social media' />
                            <Button action={ () => navigate('/dashboard/notify') } title='Notificaties' />
                            <Button action={ () => navigate('/dashboard/users') } title='Gebruikers' />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default SuccessPage