import Title from "../../../Shared/Title";
import Error from "../../../Shared/Form/Error";
import Success from "../../../Shared/Form/Success";
import React, {useEffect, useState} from "react";
import {useUploadMutation} from "../../../../Redux/Api/Api";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import {all, max, min} from "../../../../Utils/InValidation";
import Input from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import {useAppSelector} from "../../../../Redux/store";
import {Hydra} from "../../../../Utils/Hydra";
import {faCardsBlank, faPlus} from "@fortawesome/pro-solid-svg-icons";
import Table from "../../../Shared/Table/Table";
import Column from "../../../Shared/Table/Column";
import Header from "../../../Shared/Table/Header";
import Row from "../../../Shared/Table/Row";
import moment from "moment";
import Data from "../../../Shared/Table/Data";
import { useLazyGetSponsorDataQuery } from "../../../../Redux/Api/Organisations";
import LoadingPage from "../../LoadingPage";
import {useNavigate} from "react-router-dom";
import {useDeleteSponsorMutation} from "../../../../Redux/Api/Sponsors";
import {useTranslation} from "react-i18next";

const SponsorListPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const org_id = useAppSelector((s) => s.organisation.id)!;

    const [ uploadFile ] = useUploadMutation();

    const [ busy, setBusy ] = useState(false);
    const [ error, setError ] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('');
    const [ fileBlob, setFileBlob ] = useState('');

    const [ getSponsorData, { data } ] = useLazyGetSponsorDataQuery();
    const [ deleteSponsor ] = useDeleteSponsorMutation();

    useEffect(() => {
        if(org_id)
            getSponsorData({
                id: org_id
            })
    }, [ org_id ])

    const deleteSponsorAction = async (id: string) => {
        if(busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            await deleteSponsor(id);

            if(org_id)
                getSponsorData({
                    id: org_id
                });
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }
        setBusy(false);
    }

    if(!data) return <LoadingPage/>

    return (
        <>
            <Title text={t('sponsor:title')} />
            <Error text={ error } />
            <Success text={ successMessage } />

            <BusyArea busy={ busy }>
                <Table subheader={(
                    <div className='mb-2 flex gap-4'>
                        <Button action={ () => navigate('/dashboard/sponsor/add') } className='my-6' title={t('sponsor:add')} icon={ faPlus } iconright />
                    </div>
                )} withSearch withDelete>
                    <Header>
                        <Column name={t('sponsor:name')}/>
                        <Column name={t('sponsor:logo')}/>
                        <Column name={t('sponsor:activeFrom')}/>
                        <Column name={t('sponsor:activeUntil')}/>
                    </Header>
                    <Data>
                        { data.map(({ id, name, icon, from, until }, i) => (
                            <Row key={ id }
                                 index={ i }
                                 indexKey={ id }
                                 deleteAction={ () => deleteSponsorAction(id) }
                                 deleteConfirmMessage={t('modal:delete') as string}
                                 data={[
                                     name,
                                     icon,
                                     moment(from).format('DD-MM-YYYY'),
                                     moment(until).format('DD-MM-YYYY')
                                 ]}/>
                        )) }
                    </Data>
                </Table>
            </BusyArea>
        </>
    )
}

export default SponsorListPage