import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import Api from './Api/Api'
import { rtkQueryUnauthorizedHandler } from './Middleware/Unauthorized'
import authReducer from './State/Auth'
import organisationReducer from './State/Organisation'
import appReducer from './State/App'
import dataGridReducer from './Slices/Datagrid'
import layoutReducer from './Slices/layout';

// Add your reducers here
const reducer = {
    [Api.reducerPath]: Api.reducer,
    organisation: organisationReducer,
    auth: authReducer,
    app: appReducer,
    layout: layoutReducer,
    datagrid: dataGridReducer
}

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(Api.middleware)
        .concat(rtkQueryUnauthorizedHandler),
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
