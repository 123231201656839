import React from 'react'

export interface CardGridProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    cols?: number
}


const CardGrid = (props: CardGridProps) => {

    const { cols, ...restProps } = props

    return (
        // @ts-ignore
        <div {...restProps} style={{...props.style,  '--cols': `repeat(${ cols || 2 }, minmax(0, 1fr))` }} className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-[var(--cols)] gap-6 my-5'/>
    )
}

export default CardGrid