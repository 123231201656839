import React, { useEffect, useState } from 'react'
import {Outlet, useParams} from 'react-router-dom'
import Main from './Main'
import { useAppSelector } from '../Redux/store'
import Sidebar from './Shared/Sidebar/Sidebar'
import SidebarItem from './Shared/Sidebar/SidebarItem'
import SidebarUser from './Shared/Sidebar/SidebarUser'
import { JWT } from '../Utils/JWT'
import WizardPage from './Pages/Dashboard/Wizard/WizardPage'
import LoadingPage from './Pages/LoadingPage'
import {useLazyGetOrganisationSettingsQuery} from "../Redux/Api/Organisations";
import {useTranslation} from "react-i18next";
import { Snackbar } from './Layout/Snackbar'

const DashboardWrapper = () => {
    const org_id = useAppSelector((s) => s.organisation.id)!
    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const [ hasLoaded, setHasLoaded ] = useState(false)
    const is_new = useAppSelector((s) => s.organisation.settings?.new) || false
    const roles = JWT.read<Array<string>>('roles') || []
    const [ getOrganisationsSettings, {data: settings} ] = useLazyGetOrganisationSettingsQuery()
    const { t } = useTranslation()

    useEffect(() => {
        if(org_id)
            getOrganisationsSettings(org_id)
    }, [ org_id, settings ])

    useEffect(( ) => {
        if(theme) setHasLoaded(true)
    }, [ theme ])

    return (
        <>
            <style>
                {`
                    :root {
                        --color-accent: ${ theme?.colors.tint || 'rgb(234, 45, 55)' };
                        --color-accent-light: ${ theme?.colors.lighterTint || 'rgb(238, 88, 96)' };
                    }
                `}
            </style>
            <Sidebar style={{ boxShadow: '0px 3px 40px #294D910F' }}>
                { (!is_new || roles.includes('ROLE_SUPER_ADMIN')) && (
                    <div className='flex flex-col flex-grow overflow-auto mt-20' style={{ overflow: 'hidden' }}>
                        <SidebarItem exact link="/dashboard" title={t('sidebar:dashboard')}/>
                        <SidebarItem link="/dashboard/cards" title={t('sidebar:cards')}>
                            <SidebarItem sub link="/dashboard/cards/list" title={t('sidebar:list')} />
                            <SidebarItem sub link="/dashboard/cards/create" title={t('sidebar:add')} />
                            <SidebarItem sub link="/dashboard/cards/import" title={t('sidebar:import')} />
                            <SidebarItem sub link="/dashboard/cards/appbuilder" title={t('sidebar:app')}/>
                        </SidebarItem>
                        <SidebarItem link="/dashboard/organisation"  title={t('sidebar:organisation')}>
                            <SidebarItem sub link="/dashboard/organisation/theme" title={t('sidebar:theme')} />
                            <SidebarItem sub link="/dashboard/organisation/socials" title={t('sidebar:social')} />
                            <SidebarItem sub link="/dashboard/organisation/news" title={t('sidebar:news')} />
                        </SidebarItem>
                        {(settings?.notification) ? (
                            <SidebarItem link="/dashboard/notify" title={t('sidebar:notification')}/>
                        ) : null}
                        <SidebarItem link="/dashboard/list" title={t('sidebar:user')}>
                            <SidebarItem sub link="/dashboard/users/list" title={t('sidebar:list')} />
                            <SidebarItem sub link="/dashboard/users/create" title={t('sidebar:add')} />
                        </SidebarItem>
                        {(settings?.sponsors) && (
                            <SidebarItem link='/dashboard/sponsor' title={t('sidebar:sponsors')}>
                                <SidebarItem sub link="/dashboard/sponsor/list" title={t('sidebar:list')} />
                                <SidebarItem sub link="/dashboard/sponsor/add" title={t('sidebar:add')} />
                            </SidebarItem>
                        )}
                        { (roles.includes('ROLE_SUPER_ADMIN')) && (
                            <SidebarItem bottom link="/dashboard/admin" title="Admin">
                                <SidebarItem sub link="/dashboard/admin/organisation/list" title="Organisaties" />
                                <SidebarItem sub link="/dashboard/admin/organisation/create" title={t('sidebar:addOrganisation')} />
                                <SidebarItem sub link="/dashboard/admin/releaseNote/list" title='notes' />
                                <SidebarItem sub link="/dashboard/admin/releaseNote/create" title={t('sidebar:addNote')} />
                            </SidebarItem>
                        )}
                    </div>
                )}
                <SidebarUser/>
            </Sidebar>
            <Main>
                {  (is_new && !roles.includes('ROLE_SUPER_ADMIN')) ? hasLoaded ? (
                    <WizardPage/>
                ): <LoadingPage/> : (
                    <><Outlet/><Snackbar/></>
                ) }
            </Main>
        </>
    )
}

export default DashboardWrapper
