import { HTMLMotionProps, motion } from 'framer-motion'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import {  TableContext } from './Table'

export interface DataProps extends HTMLMotionProps<"tbody"> {
    children: Array<ReactElement>
}

const Data = (props: DataProps) => {

    const { children, ...restProps } = props

    const ctx = useContext(TableContext)

    const [ rows, setRows ] = useState<Array<ReactElement>>([])

    const sort = (a: ReactElement, b: ReactElement) => {

        const sortValue = ctx.sort
        const columns = ctx.columns

        const index = Object.keys(columns).indexOf(sortValue[0])

        if(typeof a.props.data[index] == 'string' && typeof b.props.data[index] == 'string') {
            return (a.props.data[index] || '').localeCompare(b.props.data[index] || '') * (sortValue[1] == 'down' ? 1 : -1)
        } else if(typeof a.props.data[index] == 'number' && typeof b.props.data[index] == 'number') {
            return ((a.props.data[index] || '') - (b.props.data[index] || '')) * (sortValue[1] == 'down' ? 1 : -1)
        }

        return 1
    }

    const filterVisible = (a: ReactElement, index: number) => {
        return a.props.data.join(' ').toLowerCase().includes(ctx.search.toLowerCase())
    }

    const pageFilter = (_: any, index: number) => {
        const page = ctx.page
        const itemsPerPage = ctx.itemsPerPage
        const start = page * itemsPerPage
        const end = start + itemsPerPage
        return start <= index && index < end
    }
    
    useEffect(() => {
        const items = [...(children || [])].filter(filterVisible)
        ctx.setTotal(items.length)
        setRows(items.filter(pageFilter).sort(sort))
        ctx.setSelectAll(() => selectAll)
    }, [ ctx.page, children, ctx.search, ctx.sort ])

    const selectAll = () => {
        const items = [...(children || [])].filter(filterVisible)
        const selection: Record<any,any> = {}
        for(let item of items) {
            selection[item.props.index] = item.props.indexKey
        }
        ctx.setSelection(selection)
    }

    return (
        <motion.tbody {...restProps}>
            { rows }
        </motion.tbody>
    )
}

export default Data