import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../Shared/Form/Button'
import Form from '../../Shared/Form/Form'
import Input from '../../Shared/Form/Input'
import Title from '../../Shared/Title'
import SidePanelWrapper from './../SidePanelWrapper'
import {Strings} from "../../../Strings/nl";
import DigipasLogoWidePNG from "../../../Assets/digipaslogowide.png";
import {useForgotPasswordMutation} from "../../../Redux/Api/Auth";
import {useAppSelector} from "../../../Redux/store";
import {Hydra} from "../../../Utils/Hydra";
import Success from "../../Shared/Form/Success";
import Error from "../../Shared/Form/Error";
import BusyArea from "../../Shared/BusyArea";

const ForgotPasswordPage = () => {
    const navigate = useNavigate()

    const logoutError = useAppSelector((s) => s.auth.logoutReason);

    const [ forgotPassword, setForgotPassword ] = useForgotPasswordMutation();
    const [ error, setError ] = useState<string | undefined>(logoutError);
    const [ successMessage, setSuccessMessage ] = useState<string>('');
    const [ busy, setBusy ] = useState(false);

    const submit = async (data: { email: string }) => {
        if(busy) return

        setBusy(true);
        setSuccessMessage('');
        setError('');

        try {
            await forgotPassword(data).unwrap();
            setSuccessMessage(`Er is een mail gestuurd naar `+ data.email);
        } catch (e) {
            setError('Er is een fout opgetreden.');
        }
        setBusy(false);
    }

    return (
        <>
            <SidePanelWrapper>
                <img src={ DigipasLogoWidePNG } className="w-10/12 mb-16"/>
                <Success text={ successMessage }/>
                <Error text={ error }/>
                <Title text='Wachtwoord resetten' subtitle='Voer je emailadres in en reset je wachtwoord' />
                <BusyArea busy={ busy }>
                    <Form submit={ submit }>
                        <Input label='Email' id='email' />
                        <div className='flex gap-4 flex-wrap'>
                            <Button title='Reset'/>
                            <Button icon={ faArrowLeft } nosubmit action={ () => navigate('/login') } title='Back'/>
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    )
}

export default ForgotPasswordPage