import React, { useRef, useEffect, useState } from 'react';
import {
  useGetReleaseNotesQuery,
} from '../../../../Redux/Api/ReleaseNote';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimes, faPlus} from '@fortawesome/pro-solid-svg-icons'
import LoadingPage from '../../LoadingPage'
import moment from 'moment'
import {useTranslation} from "react-i18next";

type LanguageKey = `text_${string}`;

type TextResponseItem = {
  [key in LanguageKey]: string;
};

type ReleaseNoteItem = {
  version: string;
  date: string;
  text: TextResponseItem;
};

type ReleaseNoteResponse = {
  data: ReleaseNoteItem[];
};

const ReleaseNotes = () => {
  const releaseNotesRef = useRef<HTMLUListElement>(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [currentReleaseIndex, setCurrentReleaseIndex] = useState(0);
  const [animationDuration, setanimationDuration] = useState(0);
  const [animationlength, setanimationlength] = useState(0);
  const { data: ReleaseNoteResponse, refetch } = useGetReleaseNotesQuery<ReleaseNoteResponse>();
  const [releaseText, setReleaseText ] = useState('')
  const { t, i18n  } = useTranslation()

  useEffect(() => {
    const releaseNotes = releaseNotesRef.current;
    if (!releaseNotes) return;
    if(ReleaseNoteResponse){
      const notesLength = ReleaseNoteResponse[currentReleaseIndex].text.text_nl.length;

      if (notesLength > 75) {
        setShouldAnimate(true);
        setanimationlength( 100 + notesLength / 10);
        setanimationDuration(notesLength * 200);
      } else {
        setShouldAnimate(false);
      }
    }
  }, [currentReleaseIndex]);

  useEffect(() => {
    if (ReleaseNoteResponse && ReleaseNoteResponse.length > 0) {
        const textKey: LanguageKey = `text_${i18n.language}`;
        setReleaseText(ReleaseNoteResponse[currentReleaseIndex].text[textKey] ?? '');
    }
  }, [i18n.language, currentReleaseIndex, ReleaseNoteResponse]);

  useEffect(() => {
    const releaseNotes = releaseNotesRef.current;
    if (!releaseNotes) return;
  
    if (shouldAnimate) {
      releaseNotes.style.animationDuration = `${animationDuration}ms`;
      const handleAnimationIteration = () => {
        releaseNotes.style.transform = 'translateX(100%)';
      };
  
      releaseNotes.addEventListener('animationiteration', handleAnimationIteration);
  
      return () => {
        releaseNotes.removeEventListener('animationiteration', handleAnimationIteration);
      };
    }
  }, [shouldAnimate, animationDuration]);

  if(!ReleaseNoteResponse) return <LoadingPage/>;
  
  const handleHideReleaseNotes = () => {
    setIsVisible(false);
  };

  const handleShowReleaseNotes = () => {
    setIsVisible(true);
  };

  const handleNextRelease = () => {
    setCurrentReleaseIndex((prevIndex) => (prevIndex === ReleaseNoteResponse.length - 1 ? 0 : prevIndex + 1));
    const releaseNotes = releaseNotesRef.current;
    if (releaseNotes && shouldAnimate) {
      releaseNotes.style.transform = 'translateX(-100%)';
    }
  };
  
  const handlePrevRelease = () => {
    setCurrentReleaseIndex((prevIndex) => (prevIndex === 0 ? ReleaseNoteResponse.length - 1 : prevIndex - 1));
    const releaseNotes = releaseNotesRef.current;
    if (releaseNotes && shouldAnimate) {
      releaseNotes.style.transform = 'translateX(-100%)';
    }
  };
  
  return (
    <>
      {ReleaseNoteResponse.length < 1 ? (
        <div className='px-2 py-2' style={{ border: '1px solid #D2DCEA', borderRadius: '50px', display: 'flex', position: 'absolute', top: '20px', right: '20px' }}>
          <a className='pl-3 pt-1'>{t('releasenote:updates')}(0)</a>
          <div className="px-2 ml-5 " style={{ backgroundColor: '#0175FF', borderRadius: '200px', cursor: 'pointer', color: 'white', fontSize: '22px', zIndex: 1 }}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
      ) : (
        <>
        <div className='mx-auto' style={{ overflow: 'hidden', position: 'absolute', height: '50px', backgroundColor: '#0175FF', width: '100vw', top: '0', right: '0',display: isVisible ? '' : 'none' }}>
          <div className="arrow-left" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '330px', cursor: 'pointer', color: 'white', fontSize: '20px', zIndex: 1 }} onClick={handlePrevRelease}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className="arrow-right" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '60px', cursor: 'pointer', color: 'white', fontSize: '20px', zIndex: 1 }} onClick={handleNextRelease}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div className="px-2" style={{ backgroundColor: '#018FFF', borderRadius: '200px', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '10px', cursor: 'pointer', color: 'white', fontSize: '18px', zIndex: 1 }} onClick={handleHideReleaseNotes}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
  
          <ul
            ref={releaseNotesRef}
            style={{
              display: 'flex',
              flexDirection: 'row',
              listStyleType: 'none',
              padding: 0,
              margin: 0,
              position: 'absolute',
              animation: shouldAnimate ? 'moveText linear infinite' : '',
              transform:  shouldAnimate ? 'translateX(100%)' : '',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <style>
              {`
                @keyframes moveText {
                  0% {
                    transform: translateX(${animationlength}%);
                  }
                  100% {
                    transform: translateX(-100%);
                  }
                }
              `}
            </style>
            <div>
              <div className='py-3' style={{ display: 'flex', flexDirection: 'row', color: 'white', paddingLeft: shouldAnimate ? '' : '288px', zIndex: 1 }}>
                <h3 style={{ marginRight: '20px' }} ><strong> {t('releasenote:version')}</strong> {ReleaseNoteResponse[currentReleaseIndex].version}</h3>
                <p style={{ marginRight: '20px' }} ><strong> {t('releasenote:date')}</strong> {moment(ReleaseNoteResponse[currentReleaseIndex].date).format('DD-MM-YYYY')}</p>
                <p style={{ marginRight: '20px' }}><strong>{t('organisation:news:text')}</strong> {releaseText}</p>
              </div>
            </div>
          </ul>
        </div>
  
        <div className='px-2 py-2' style={{ border: '1px solid #D2DCEA', borderRadius: '50px', display: isVisible ? 'none' : 'flex', position: 'absolute', top: '20px', right: '20px' }}>
          <a className='pl-3 pt-1'>{t('releasenote:updates')}({ReleaseNoteResponse.length})</a>
          <div className="px-2 ml-5 " style={{ backgroundColor: '#0175FF', borderRadius: '200px', cursor: 'pointer', color: 'white', fontSize: '22px', zIndex: 1 }} onClick={handleShowReleaseNotes}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default ReleaseNotes;