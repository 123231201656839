import React from 'react'
import { all, max, min } from '../../../Utils/InValidation'
import Button from '../../Shared/Form/Button'
import Form from '../../Shared/Form/Form'
import Input from '../../Shared/Form/Input'
import Title from '../../Shared/Title'

const UserSettingsPage = () => {

    const submit = (data: {
        email: string,
        firstname: string,
        lastname: string
    }) => {

    }

    const submitPassword = (data: {
        current_password: string,
        password: string,
        password2: string
    }) => {

    }

    return (
        <>
            <Title text='Gebruikers instellingen'/>
            <Form submit={ submit }>
                <Title text='Info'/>
                <Input
                    required
                    invalidator={ all(min(1), max(25)) }
                    label='Email'
                    type='email'
                    id='email'/>
                <Input
                    required
                    invalidator={ all(min(1), max(25)) }
                    label='Voornaam'
                    id='firstname'/>
                <Input
                    required
                    invalidator={ all(min(1), max(25)) }
                    label='Achternaam'
                    id='lastname'/>
                <Button title='Opslaan' />
            </Form>

            <Form submit={ submitPassword }>
                <Title text='Wachtwoord'/>
                <Input
                    required
                    invalidator={ all(min(1), max(55)) }
                    label='Oud wachtwoord'
                    type='password'
                    id='current_password'/>
                <Input
                    required
                    invalidator={ all(min(1), max(55)) }
                    label='Nieuw wachtwoord'
                    type='password'
                    id='password'/>
                <Input
                    required
                    invalidator={ all(min(1), max(55)) }
                    label='Bevestig nieuw wachtwoord'
                    type='password'
                    id='password2'/>
                <Button title='Opslaan' />
            </Form>
        </>
    )
}

export default UserSettingsPage