import {faArrowLeft, faSpinner} from '@fortawesome/pro-solid-svg-icons'
import React, {useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import Button from '../../Shared/Form/Button'
import Form from '../../Shared/Form/Form'
import Input from '../../Shared/Form/Input'
import Title from '../../Shared/Title'
import SidePanelWrapper from './../SidePanelWrapper'
import {Strings} from "../../../Strings/nl";
import DigipasLogoWidePNG from "../../../Assets/digipaslogowide.png";
import {useAppSelector} from "../../../Redux/store";
import Success from "../../Shared/Form/Success";
import Error from "../../Shared/Form/Error";
import BusyArea from "../../Shared/BusyArea";
import {useResetPasswordMutation} from "../../../Redux/Api/Auth";

const ResetPasswordPage = () => {
    const navigate = useNavigate()

    const logoutError = useAppSelector((s) => s.auth.logoutReason);

    const [ error, setError ] = useState<string | undefined>(logoutError);
    const [ successMessage, setSuccessMessage ] = useState<string>('');
    const [ busy, setBusy ] = useState(false);
    const [ params, setParams ] = useSearchParams();
    const [ resetPassword, setResetPassword ] = useResetPasswordMutation();

    const submit = async (data: { password: string, confirmPassword: string }) => {
        if(busy) return

        setBusy(true);
        setSuccessMessage('');
        setError('');

        if(data.password != data.confirmPassword){
            setError('Er is een fout opgetreden');
            setBusy(false);
            return;
        }

        try {
            await resetPassword([params.get("token")!, data.password]).unwrap();
            setSuccessMessage('Wachtwoord is aangepast!');
            navigate('/login');
        } catch (e) {
            navigate('/login');
        }
        setBusy(false);
    }

    return (
        <>
            <SidePanelWrapper>
                <img src={ DigipasLogoWidePNG } className="w-10/12 mb-16"/>
                <Success text={ successMessage }/>
                <Error text={ error }/>
                <Title text='Reset wachtwoord' subtitle='Voer een nieuw wachtwoord in.' />
                <BusyArea busy={ busy }>
                    <Form submit={ submit }>
                        <Input label='Nieuw wachtwoord' type='password' id='password' />
                        <Input label='Herhaal nieuw wachtwoord' type='password' id='confirmPassword' />
                        <div className='flex gap-4 flex-wrap'>
                            <Button title='Bevestigen' />
                            <Button nosubmit action={ () => navigate('/login') } title='Annuleren'/>
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    )
}

export default ResetPasswordPage