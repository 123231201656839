import React, { useState } from 'react';
import {usePostReleaseNoteMutation} from '../../../../Redux/Api/ReleaseNote';
import BusyArea from '../../../Shared/BusyArea';
import Button from '../../../Shared/Form/Button';
import Error from '../../../Shared/Form/Error';
import Form from '../../../Shared/Form/Form';
import Input from '../../../Shared/Form/Input';
import Title from '../../../Shared/Title';
import { useTranslation } from 'react-i18next';
import Success from '../../../Shared/Form/Success'


const ReleaseNodeCreatePage = () => {
    const [ postReleaseNode ] = usePostReleaseNoteMutation();

    const { t } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('');

    const languages = ['nl', 'en', 'de', 'fr', 'es'];

    const submit = async (data: {
        title: string,
        version: string,
        text_nl: string,
        text_en: string,
        text_de: string,
        text_fr: string,
        text_es: string,
    }) => {
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');
    
        for (const language of languages) {
            if ((data as any)[`text_${language}`].length > 150) {
                setError(`${t('organisation:news:tooLong')} (${language.toUpperCase()})`);
                setBusy(false);
                return;
            }
        }
    
        try {
            const outData = {
                title: data.title,
                text: {
                    text_nl: data.text_nl,
                    text_en: data.text_en,
                    text_de: data.text_de,
                    text_fr: data.text_fr,
                    text_es: data.text_es,
                },
                version: data.version,
            };
    
            await postReleaseNode([outData]).unwrap();
    
            setSuccessMessage(t('releasenote:successAdd') as string);
            setBusy(false);
        } catch (e) {
            setError(t('modal:error') as string);
            setBusy(false);
        }
    }
    


    return (
        <>
            <Title text={t('releasenote:createReleasenote')} textNotBold/>
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <Error text={error} />
                    <Success text={ successMessage } />
                    <Input required label={t('releasenote:title')} id='title' />
                    <Input required label={t('releasenote:version')} id='version' />
                    <h1 className='text-2xl font-bold'>{t('organisation:news:text')}</h1>
                    <br />
                    <Input required label={t('organisation:news:text') + '(NL)'} id='text_nl' />
                    <Input required label={t('organisation:news:text') + '(EN)'} id='text_en' />
                    <Input required label={t('organisation:news:text') + '(DE)'} id='text_de' />
                    <Input required label={t('organisation:news:text') + '(FR)'} id='text_fr' />
                    <Input required label={t('organisation:news:text') + '(ES)'} id='text_es' />
                    <Button title={t('releasenote:add')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default ReleaseNodeCreatePage;
