import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../Shared/Form/Button'
import Title from '../Shared/Title'

const Page404 = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className='flex w-full h-full items-center justify-center'>
                <div className='flex flex-col p-20'>
                    <Title text='404' subtitle="Oeps... We kunnen deze pagina niet vinden."/>
                    <Button action={ () => navigate(-1) } icon={ faArrowLeft } title='Terug'/>
                </div>
            </div>
        </>
    )
}

export default Page404