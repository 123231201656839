import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DateRangePicker } from 'react-date-range';
import { nlBE } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface GraphFilterProps {
    icon?: IconDefinition;
    onDatesChange?: (dates: { fromDate: Date; toDate: Date }) => void;
}

const GraphFilter = ({ icon, onDatesChange }: GraphFilterProps) => {
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [selected, setSelected] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
        }
    ]);

    const [open, setOpen] = useState<boolean>(false);
    const refOne = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (onDatesChange) {
            onDatesChange({ fromDate: new Date(), toDate: new Date() });
        }
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true);
            document.removeEventListener("click", hideOnClickOutside, true);
        };
    }, []);
    
    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    };

    const hideOnEscape = (e: any) => {
        if (e.key === "Escape") {
            setOpen(false);
        }
    };

    const hideOnClickOutside = (e: any) => {
        //@ts-ignore
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const handleDateChange = (ranges: any) => {
        setRange([ranges.selection]);
    };

    const confirm = () => {
        const fromDate = new Date(range[0].startDate);
        const toDate = new Date(range[0].endDate);

        if (onDatesChange) {
            onDatesChange({ fromDate, toDate });
        }

        setSelected([
            {
                startDate: fromDate,
                endDate: toDate,
            }
        ]);
    };

    const isSelectedToday = (date: Date) => {
        if (!(date instanceof Date)) {
            return false;
        }
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };
    
    
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <div className='justify-end flex flex-row'>
                <div>
                    <div className='flex justify-end'>
                        {icon && (
                            <div className="items-center flex">
                                <FontAwesomeIcon className='text-lg font-semibold font-title mr-4' style={{ fontSize: '1.2rem' }} icon={icon} />
                            </div>
                        )}
                        <input
                            value={`${formatDateToYMD(selected[0].startDate, 3)} tot ${formatDateToYMD(selected[0].endDate, 3)}`}
                            readOnly
                            className={`px-3 py-3 flex focus:outline-none`}
                            style={{
                                width: '250px',
                                border: open ? '1px solid #0175FF' : '1px solid #D7E3F2',
                                fontFamily: 'Inter'
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    </div>
                </div>
            </div>
            {open && (
                <div ref={refOne} style={{ zIndex: 9999, position: 'absolute', right: '0', left: 'calc(100% - 558px)', top: '51px' }}>
                        <DateRangePicker
                            onChange={handleDateChange}
                            editableDateInputs={false}
                            moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            months={1}
                            ranges={range}
                            locale={nlBE}
                            direction="horizontal"
                            className='border-gray-200 border-t-2 border-r-2 border-l-2'
                                staticRanges={[
                                    {
                                        label: t('overview:Today'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => ({ startDate: new Date(), endDate: new Date() }),
                                    },
                                    {
                                        label: t('overview:Yesterday'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const yesterday = new Date();
                                            yesterday.setDate(yesterday.getDate() - 1);
                                            return { startDate: yesterday, endDate: yesterday };
                                        },
                                    },
                                    {
                                        label: t('overview:ThisWeek'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const today = new Date();
                                            const startOfWeek = new Date(today);
                                            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + (startOfWeek.getDay() === 0 ? -6 : 1));
                                            const endOfWeek = new Date(today);
                                            endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 7);
                                            return { startDate: startOfWeek, endDate: endOfWeek };
                                        },
                                    },
                                    {
                                        label: t('overview:LastWeek'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const today = new Date();
                                            const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
                                            const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                                            return { startDate: lastWeekStart, endDate: lastWeekEnd };
                                        },
                                    },
                                    {
                                        label: t('overview:ThisMonth'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const today = new Date();
                                            const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                                            const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                                            return { startDate: firstDayOfThisMonth, endDate: lastDayOfThisMonth };
                                        },
                                    },
                                    {
                                        label: t('overview:LastMonth'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const today = new Date();
                                            const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                                            const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                                            return { startDate: firstDayOfPreviousMonth, endDate: lastDayOfPreviousMonth };
                                        },
                                    },
                                    {
                                        label: t('overview:LastYear'),
                                        isSelected: (date: Date) => isSelectedToday(date),
                                        range: () => {
                                            const today = new Date();
                                            const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
                                            const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
                                            return { startDate: lastYearStartDate, endDate: lastYearEndDate };
                                        },
                                    }
                                    
                                    
                                ]}
                                
                        />
                        <div className='pb-4 border-gray-200 border-b-2 border-r-2 border-l-2' style={{ float: 'right', width: '555px', backgroundColor: 'white'}}>
                            <button className='text-center font-bold py-2 mr-5' style={{float: 'right', color: 'white', fontSize: '16px',width: '155px',backgroundColor: '#0175FF' }} onClick={confirm}>
                                {t('overview:adjust')}
                            </button>
                        </div>
                    </div>
            )}
        </div>
    );
};

export default GraphFilter;
