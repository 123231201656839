import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import { createPortal } from 'react-dom'
import { Strings } from '../../../Strings/nl'
import {useTranslation} from "react-i18next";

export interface ColorPickerProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,'onChange'> {
    label: string,
    sublabel?: string,
    initial?: string,
    id?: string,
    color?: string,
    onChange?: (color: string) => void,
    onChangeReactive?: (color: string) => void
}

const ColorPicker = (props: ColorPickerProps) => {
    const { t } = useTranslation()
    const { label, sublabel, onChange, color, onChangeReactive, initial, id, ...restProps } = props

    const labelOrId = id || label

    const [ value, setValue ] = useState(initial || '')
    const [ isOpen, setIsOpen ] = useState(false)

    const change = (col: ColorResult) => {
        setValue(col.hex)

        if(onChangeReactive) onChangeReactive(col.hex)
    }

    useEffect(() => {
        if(color) setValue(color)
    }, [ color ])

    const itemRef = useRef<HTMLDivElement>(null)

    return (
        <div {...restProps} className='flex flex-col mb-6'>
            { <motion.div animate={ (value.length > 0) ? { height: '0rem' } : { height: '1.2rem' } } className='text-sm overflow-hidden text-red-400 mb-2'>{ (value.length <= 0 && t('required')) || '\u00a0' }</motion.div> }
            <label htmlFor={ labelOrId }>{ label }<span className='text-red-400'>*</span> { sublabel && <span className='opacity-70 text-sm'>({ sublabel })</span> }</label>
            <div className='flex flex-grow mt-1 relative'>
                <div ref={ itemRef } onClick={ () => setIsOpen((s) => !s) } className='flex items-center cursor-pointer border-2 border-black border-opacity-10 flex-grow p-1'>
                    <div style={{ background: value }} className="w-4 h-4 ml-1 mr-2 rounded-full"></div>
                    { value || '\u00a0' }
                </div>
                { createPortal((
                    <AnimatePresence>
                        { isOpen && <div className='fixed left-0 top-0 w-screen h-screen z-40'>
                                <motion.div 
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className='absolute z-50'
                                    style={{ left: itemRef.current?.getBoundingClientRect().left + 'px', top: itemRef.current?.getBoundingClientRect().top + 'px' }}>
                                    <ChromePicker 
                                        disableAlpha
                                        color={ value }
                                        onChangeComplete={ (col) => onChange && onChange(col.hex) }
                                        onChange={ change  }
                                        className='mt-10'/>
                                </motion.div>
                                <motion.div 
                                    key="background-div"
                                    onClick={ () => setIsOpen(false) }
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 0.3 }}
                                    exit={{ opacity: 0 }}
                                    className='fixed left-0 top-0 w-screen h-screen bg-black'/>
                        </div> }
                    </AnimatePresence>),document.getElementById('overlay')!)
                }
            </div>
            <input value={ value } type='hidden' name={ labelOrId } id={ labelOrId } />
            <input name={ '@VALID@' + labelOrId } type="hidden" value={ (value.length > 0) + '' } />
        </div>
    )
}

export default ColorPicker